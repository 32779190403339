import React, { useState, useEffect, useRef } from 'react'

// MUI Imports
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Third-party Imports
import classnames from 'classnames'
import { Icon } from '@iconify/react';
import { Link } from 'react-scroll';

// Hook Imports
import { useNavigate } from 'react-router-dom'
import { useSettings } from '../../hooks/useSetting';

// Component Imports
import UsefulFeature from './useful_feature/UsefulFeature';
import OurTeam from './our_team/OurTeam';
import LandingReviews from './landing_reviews/LandingReviews';
import Logo from '../../component/shared/logo/Logo';
import ModeDropDown from '../../component/shared/mode_drop_down/ModeDropDown';
import LandingPageDrawer from './landingpage_drawer/LandingPageDrawer';
import LandingFaqs from './landing_faqs/LandingFaqs';
import LandingTopScore from './landing_top_score/LandingTopScore';
import LandingContactUs from './landing_contact_us/LandingContactUs';

// Vars
const heroBg = "/images/pages/landingpage/hero-bg.png";
const joinArrow = "/images/pages/landingpage/Join-community-arrow.png";

LandingPage.path = "/"
function LandingPage() {

  // States
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Hooks
  const landingRef = useRef(null);
  const navigate = useNavigate();
  const { settings } = useSettings();

  const mode = settings.mode == 'system' ? settings.systemMode : settings.mode

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  };


  return (
    <div id="home" className='flex flex-col bg-backgroundPaper'>
      <LandingPageDrawer open={isDrawerOpen} setOpen={setIsDrawerOpen} />
      <div className='flex justify-center items-center mt-6 fixed w-full z-10'>
        <div className={classnames(
          'flex justify-between px-8 py-4 w-[90%] sm:w-[80%] shadow-secondarySm rounded-lg border-solid border-white border-[0.5px] transition-all duration-300',
          {
            'bg-backgroundPaper': scrollPosition > 20
          }
        )}>
          <div className='flex items-center gap-6'>
            <div className='flex items-center gap-3'>
              <Icon className='flex text-textSecondary lg:hidden' icon='mingcute:menu-line' style={{ fontSize: '24px' }} onClick={() => setIsDrawerOpen(true)} />
              <Logo />
            </div>
            <div className='hidden ml-12 gap-4 text-textSecondary lg:flex'>
              <Link
                className='font-bold cursor-pointer hover:text-primary'
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}>خانه</Link>
              <Link
                className='font-bold cursor-pointer hover:text-primary'
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}>قابلیت ها</Link>
              <Link
                className='font-bold cursor-pointer hover:text-primary'
                activeClass="active"
                to="faq"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}>سوالات متداول</Link>
              <Link
                className='font-bold cursor-pointer hover:text-primary'
                activeClass="active"
                to="contactUs"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}>تماس با ما</Link>
            </div>
          </div>
          <div className='flex gap-1 md:gap-4'>
            <ModeDropDown />
            <Button
              variant='contained'
              type='submit'
              startIcon={<Icon style={{ fontSize: '22px' }} icon='tabler:login' />}
              onClick={() => navigate('/login')}>
              <span className='hidden md:flex'>ورود/ عضویت</span>
            </Button>
          </div>
        </div>
      </div>

      <div className={classnames(
        'rounded-b-[3.5rem] min-h-[500px] w-full relative pt-[10.2rem]',
        {
          'bg-gradient-138 from-[#eae8fd] to-[#fce5e6]': mode == 'light',
          'bg-[#1e2130]': mode == 'dark',
        }
      )}>
        <img
          src={heroBg}
          className='absolute w-full h-full top-0'
        />
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-center max-w-[34.375rem] text-center px-4 text-base md:text-xl'>
            <h1 className="gradient-text-landing">منتظر دریافت صدای زیبای شما هستیم</h1>
            <Typography variant='subtitle1' className='!mt-2'>ما با مجرب ترین پیمانکاران آماده انجام سفارشات شما در کمترین زمان و با بهترین کیفیت ممکن هستیم.</Typography>
          </div>
          <div className='relative mt-6' dir='ltr'>
            <Button variant='contained'>
              شروع به استفاده
            </Button>
            <div className='relative'>
              <div className='hidden md:flex absolute start-[-160px] top-[-10px]'>
                <Typography>به جمع ما بپیوندید</Typography>
                <img
                  src={joinArrow}
                  alt="Join community arrow"
                  className="scaleX-n1-rtl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-2 md:mt-[80px] lg:mt-[140px]'>
        <Grid container justifyContent="center">
          <div id='features' ref={landingRef} className='pt-28 flex items-center justify-center'>
            <Grid item xs={12} md={9}>
              <UsefulFeature />
            </Grid>
          </div>
          <Grid item xs={12}>
            <LandingReviews mode={mode} />
          </Grid>
          <Grid item xs={12} md={9}>
            <OurTeam />
          </Grid>
          <Grid item xs={12}>
            <div id='faq' ref={landingRef} className='pt-28'>
              <LandingFaqs mode={mode} />
            </div>
          </Grid>
          <Grid item xs={12} md={9} className='pt-28'>
            <LandingTopScore />
          </Grid>
          <Grid item xs={12}>
            <div id='contactUs' ref={landingRef} className='pt-28'>
              <LandingContactUs mode={mode} />
            </div>
          </Grid>
        </Grid>
      </div>


      <div className='min-h-[400px]'>

      </div>
    </div>
  )
}

export default LandingPage