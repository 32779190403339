import React, { useRef, useState } from 'react'

// MUI Imports
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

// Third-party Imports 
import { Icon } from '@iconify/react/dist/iconify.js'

// Hook Imports
import { useSettings } from '../../../../hooks/useSetting'
import { useNavigate } from 'react-router-dom'

// Styled component for badge content
const BadgeContentSpan = styled('span')({
    width: 8,
    height: 8,
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor: 'var(--mui-palette-success-main)',
    boxShadow: '0 0 0 2px var(--mui-palette-background-paper)'
})

function UserDropdown() {

    // States
    const [open, setOpen] = useState(false)

    //Hooks
    const { user, updateUser } = useSettings();
    const navigate = useNavigate()

    // Refs
    const anchorRef = useRef(null)

    const handleDropdownClose = (e) => {

        if (anchorRef.current && anchorRef.current.contains(e?.target)) {
            return
        }
        setOpen(false)
    }

    const handleUserLogout = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        updateUser({ isLogin: false })
        navigate('/')
    }

    return (
        <div>
            <div className='flex items-center gap-2'>
                <Badge
                    onClick={() => setOpen(true)}
                    ref={anchorRef}
                    overlap='circular'
                    badgeContent={<BadgeContentSpan onClick={() => setOpen(!open)} />}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    className='mis-2'
                >
                    <Icon
                        className='cursor-pointer'
                        icon='mingcute:user-3-fill'
                        fontSize={24} />
                </Badge>
                <Popper
                    open={open}
                    transition
                    disablePortal
                    placement='bottom-end'
                    anchorEl={anchorRef.current}
                    className='min-is-[240px] !mbs-3 z-[1]'
                >
                    {({ TransitionProps, placement }) => (
                        <Fade
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom-end' ? 'right top' : 'left top'
                            }}
                        >
                            <Paper className='shadow-lg'>
                                <ClickAwayListener onClickAway={e => handleDropdownClose(e)}>
                                    <MenuList>
                                        <div dir='ltr' className='flex items-center plb-2 pli-6 gap-2' tabIndex={-1}>
                                            <Icon
                                                className='cursor-pointer'
                                                icon='mingcute:user-3-fill'
                                                fontSize={24} />
                                            <Typography variant='caption'>{user?.email || ''}</Typography>
                                        </div>
                                        <Divider className='!my-2' />
                                        <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/pages/user-profile')}>
                                            <i className='tabler-user text-[22px]' />
                                            <Typography color='text.primary'>پروفایل</Typography>
                                        </MenuItem>
                                        <div className='flex items-center plb-2 pli-3'>
                                            <Button
                                                fullWidth
                                                variant='contained'
                                                color='error'
                                                size='small'
                                                endIcon={<i className='tabler-logout' />}
                                                onClick={handleUserLogout}
                                                sx={{ '& .MuiButton-endIcon': { marginInlineStart: 1.5 } }}
                                            >
                                                خروج
                                            </Button>
                                        </div>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </div>
    )
}

export default UserDropdown