import React, { useState } from 'react'

// MUI Imports
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, Divider } from '@mui/material'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import { toast } from 'react-toastify'

// Component Imports
import DialogCloseButton from '../../../component/dialog/DialogCloseButton'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'

// Api Imports
import { addTelegramUserReq } from '../../../api/privateApi'


function AddUserDialog({ open, setOpen, getTelegramBotUserList }) {

    // States
    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");

    // Hooks 
    const { updateSettings } = useSettings()

    const handleSubmit = async () => {
        try {
            if (!fullName || !username) {
                toast.error('لطفا نام و آیدی را وارد کنید.')
                return
            }
            updateSettings({ isLoading: true })
            await addTelegramUserReq({ full_name: fullName, username: username })
            setOpen(false)
            getTelegramBotUserList()

        } catch (err) {
            updateSettings({ isLoading: false })
            setOpen(false)
        }
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='sm'
            scroll='body'
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <DialogCloseButton onClick={() => setOpen(false)} disableRipple>
                <Icon icon='tabler-x' />
            </DialogCloseButton>
            <DialogTitle variant='h4' className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-10 sm:pli-16'>
                افزودن کاربر تلگرام
            </DialogTitle>
            <DialogContent className='pbs-0 sm:pli-16 sm:pbe-20'>
                <div className='flex flex-col gap-4 py-8'>
                    <CustomTextField
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        fullWidth
                        label="نام کامل کاربر"
                    />
                    <CustomTextField
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        label="آیدی تلگرام کاربر"
                    />
                    <Button fullWidth={false} variant='contained' onClick={handleSubmit}>ثبت</Button>
                </div>

            </DialogContent>
        </Dialog>
    )
}

export default AddUserDialog