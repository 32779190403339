import axios from "axios";
import checkResponse from '../utils/check_response'

const privateApi = axios.create({
    baseURL: "https://api.begobegam.com"
})

//request interceptor to add the auth token header to requests
privateApi.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            config.headers["token"] = accessToken;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const privateUrlEndpoint = '/api'

export const getUserReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/get_user`)
    let data = checkResponse(response.data)
    return data
}

export const getPlansListReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/plans/get_plans_list`)
    let data = checkResponse(response.data)
    return data
}

export const newTaskReq = async (body) => {
    const response = await privateApi.post(
        `${privateUrlEndpoint}/tasks/new_task`,
        body,
        {
            headers: { 'Content-Type': 'multipart/form-data' }
        }
    )
    let data = checkResponse(response.data)
    return data
}

export const getTaskListReq = async (body) => {
    const response = await privateApi.post(`${privateUrlEndpoint}/tasks/task_list`, body)
    let data = checkResponse(response.data)
    return data
}

export const removeApiKeyReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/remove_api_key`)
    let data = checkResponse(response.data)
    return data
}

export const generateApiKeyReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/generate_api_key`)
    let data = checkResponse(response.data)
    return data
}

export const updatePasswordReq = async (body) => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/update_password`, body)
    let data = checkResponse(response.data)
    return data
}

export const emailOtpReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/email_otp`)
    let data = checkResponse(response.data)
    return data
}

export const verifyEmailReq = async (body) => {
    const response = await privateApi.post(`${privateUrlEndpoint}/user/verify_email`, body)
    let data = checkResponse(response.data)
    return data
}

export const getTelegramBotUserListReq = async () => {
    const response = await privateApi.post(`${privateUrlEndpoint}/telegram_bot/telegram_bot_user_list`)
    let data = checkResponse(response.data)
    return data
}

export const removeTelegramBotUserReq = async (body) => {
    const response = await privateApi.post(`${privateUrlEndpoint}/telegram_bot/remove_telegram_user`, body)
    let data = checkResponse(response.data)
    return data
}

export const addTelegramUserReq = async (body) => {
    const response = await privateApi.post(`${privateUrlEndpoint}/telegram_bot/add_telegram_user`, body)
    let data = checkResponse(response.data)
    return data
}