import React from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { useColorScheme, useTheme } from '@mui/material/styles'

// Components Imports
import OptionMenu from '../../../component/option_menu/OptionMenu'

// Util Imports
import { rgbaToHex } from '../../../utils/rgbaToHex'

// Styled Component Imports
import AppReactApexCharts from '../../../styles/chart/AppReactApexCharts'

// Vars
const series = [
    { name: 'Sales', data: [32, 27, 27, 30, 25, 25] },
    { name: 'Visits', data: [25, 35, 20, 20, 20, 20] }
]


function RadarchartTask({ serverMode }) {

    // Hooks
    const theme = useTheme()
    const { mode } = useColorScheme()

    // Vars
    const _mode = (mode === 'system' ? serverMode : mode) || serverMode
    const textDisabled = rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.4)`)
    const divider = rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.12)`)

    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false }
        },
        colors: [theme.palette.primary.main, theme.palette.info.main],
        plotOptions: {
            radar: {
                polygons: {
                    connectorColors: divider,
                    strokeColors: divider
                }
            }
        },
        stroke: { width: 0 },
        fill: {
            opacity: [1, 0.85]
        },
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        markers: { size: 0 },
        legend: {
            fontSize: '13px',
            labels: { colors: rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.7)`) },
            markers: { offsetY: -1, offsetX: theme.direction === 'rtl' ? 7 : -4 },
            itemMargin: { horizontal: 9 }
        },
        grid: { show: false },
        xaxis: {
            labels: {
                show: true,
                style: {
                    fontSize: '13px',
                    colors: [textDisabled, textDisabled, textDisabled, textDisabled, textDisabled, textDisabled]
                }
            }
        },
        yaxis: { show: false },
        responsive: [
            {
                breakpoint: 1200,
                options: {
                    chart: {
                        height: 332
                    }
                }
            }
        ]
    }

    return (
        <Card>
            <CardHeader
                title='نمودار تسک ها'
                subheader='بررسی اجمالی مصرف سالانه'
                action={<OptionMenu options={['هفته گذشته', 'ماه گذشته', 'سال گذشته']} />}
            />
            <CardContent>
                <AppReactApexCharts type='radar' height={373} width='100%' series={series} options={options} />
            </CardContent>
        </Card>
    )
}

export default RadarchartTask