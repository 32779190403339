import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Chip from '@mui/material/Chip'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import classnames from 'classnames'

const questionsAnswers = [
    {
        question: 'دقت تبدیل صدا به متن چقدر است ؟',
        answer: 'در صورت استفاده از میکروفون مناسب ، اینترنت با کیفیت مطلوب و فضایی آرام و بدون نویز دقت شما بالای ۹۸٪ است. برای اطلاعات بیشتر به بخش نمونه عملکرد نرم افزار مراجعه نمایید.'
    },
    {
        question: 'آیا امکان تبدیل فایل صوتی به متن وجود دارد؟',
        answer: 'نرم افزار بگوبگم امکان تبدیل هر نوع فایل صوتی یا ویس به متن را دارد کافی است از منوی سایت وارد بخش تبدیل فایل صوتی به متن شوید سپس فایل خود را بارگذاری نمونده و در زمان تعیین شده متن آن را دریافت نمایید.'
    },
    {
        question: "آیا امکان استفاده از نرم افزار به صورت آفلاین هم وجود دارد؟",
        answer: 'خیر ، نرم افزار ای او تایپ تنها به صورت آنلاین در دسترس است.'
    },
    {
        question: 'تایپ در نرم افزار انجام می شود ولی به کندی، علت چیست ؟',
        answer: 'فرآیند تبدیل گفتار به نوشتار را مانند یک مکاله صوتی آنلاین در نظر بگیرید. صدای ارسالی شما به طرف مقابل (نرم افزار ای او تایپ) می بایست کاملا شفاف ، واضح ، بدون قطعی و نویز باشد که نرم افزار آن را درک و پردازش نماید. گاهی به علت کیفیت پایین اینترنت یا پینگ نا‌مناسب شما ، ممکن است ارسال صدای شما به نرم افزار به صورت پیوسته و شفاف صورت نگرفته و طبیعتا نرم افزار نیز نمی تواند آن را به درستی تحلیل کنید. ساده ترین پیشنهاد در صورت در دسترس بودن استفاده از اینترنت یک سرویس دهنده دیگر است. با این حال  برای حل دقیق تر این مشکل راهنمای استفاده از نرم افزار را مطالعه بفرمایید. '
    },
    {
        question: 'آیا با هندزفری هم می توانم تبدیل صدا به متن را انجام دهم ؟',
        answer: 'هم بله ، هم خیر ، هندزفری ابزار برای انتقال صدا در گوشی تلفن همراه است ، لذا استفاده از اآن در کامپیوترهای شخصی و لپ تاپ ممکن است مناسب نباشد مگر آن که تنظیمات آن را به گونه ای در سیستم خود به گونه ای صحیح انجام دهید تا به عنوان میکروفون عمل نماید.'
    }
]

function LandingFaqs({ mode }) {
    return (
        <div className={classnames(
            'gap-1 pt-24 pb-12 px-4',
            {
                'bg-[#F8F7FA]': mode == 'light',
                'bg-[#1e2130]': mode == 'dark',
            }
        )}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    <div className="text-center mb-3 pb-1">
                        <Chip label='سوالات' color='primary' variant='tonal' />
                    </div>
                    <h3 className="text-center mb-1">
                        <span className='font-normal text-2xl'> سوالات متداول </span>
                        <span className="relative font-bold z-1 text-2xl">
                            و پرتکرار
                            <img
                                src="/images/pages/landingpage/section-title-icon.png"
                                alt="laptop charging"
                                className="h-full w-[120%] top-[10px] left-[-12%] absolute object-contain bottom-0 z-1" />
                        </span>
                    </h3>
                    <div className='text-center'>
                        <Typography className='!text-textSecondary' variant='h6'>
                            برای یافتن پاسخ سؤالات متداول، این سؤالات متداول را مرور کنید.
                        </Typography>
                    </div>
                    <Grid container justifyContent='center' spacing={6} className='!mt-8'>
                        <Grid item xs={12} sm={12} md={4} xl={3}>
                            <img
                                src='/images/pages/landingpage/1.png'
                                className='max-md:hidden is-[230px]'
                                alt='john image'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} xl={9}>
                            {questionsAnswers.map((item, index) => (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<Icon icon='tabler-chevron-right' />}
                                        aria-controls='panel1a-content'
                                    >
                                        <Typography>{item.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{item.answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default LandingFaqs