// React Imports
import { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'

// Component Imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import Logo from '../../../component/shared/logo/Logo'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

// Styled Component Imports
import AuthIllustrationWrapper from '../../../component/shared/AuthIllustrationWrapper/AuthIllustrationWrapper'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'

// Third-party Imports
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Controller, useForm } from 'react-hook-form'
import { object, minLength, string, email } from 'valibot'
import { toast } from 'react-toastify'

// Api Imports
import { resetPasswordWithTokenReq } from '../../../api/publicApi'

const schema = object({
    password: string([
        minLength(1, 'لطفا رمز عبور را وارد کنید.'),
        minLength(5, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
    ]),
    confirmPassword: string([
        minLength(1, 'لطفا رمز عبور را وارد کنید.'),
        minLength(5, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
    ])
})


ResetPassword.path = "/reset-password"

function ResetPassword() {
    // States
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [errorState, setErrorState] = useState(null)

    // Hooks 
    const { updateSettings } = useSettings()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: valibotResolver(schema),
        defaultValues: {
            password: '',
            confirmPassword: ''
        }
    })

    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            toast.error('رمز عبور و تکرار آن باید مقدار یکسان داشته باشند.')
        }
        try {
            updateSettings({ isLoading: true })
            await resetPasswordWithTokenReq({ password: data.password, reset_token: searchParams.get('token') });
            updateSettings({ isLoading: false })
            toast.success('رمز عبور با موفقیت تغییر کرد.')
            reset()
            navigate('/login')
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    return (
        <div className='h-screen flex justify-center items-center'>
            <AuthIllustrationWrapper>
                <Card className='flex flex-col sm:is-[450px]'>
                    <CardContent className='sm:!p-12'>
                        <div className='flex justify-center mbe-6'>
                            <Logo />
                        </div>
                        <div className='flex flex-col gap-1 mbe-6'>
                            <Typography variant='h4'>بازنشانی رمز عبور 🔒</Typography>
                            <Typography>رمز عبور جدید شما باید با رمزهای عبور قبلی متفاوت باشد</Typography>
                        </div>
                        <form noValidate autoComplete='off' className='flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name='password'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomTextField
                                        {...field}
                                        fullWidth
                                        label='رمز عبور'
                                        placeholder='············'
                                        id='reset-password'
                                        type={isPasswordShown ? 'text' : 'password'}
                                        onChange={e => {
                                            field.onChange(e.target.value)
                                            errorState !== null && setErrorState(null)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                                                        <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        {...(errors.password && { error: true, helperText: errors.password.message })}
                                    />
                                )}
                            />
                            <Controller
                                name='confirmPassword'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomTextField
                                        {...field}
                                        fullWidth
                                        label='تکرار رمز عبور'
                                        placeholder='············'
                                        id='confirm-reset-password'
                                        type={isPasswordShown ? 'text' : 'password'}
                                        onChange={e => {
                                            field.onChange(e.target.value)
                                            errorState !== null && setErrorState(null)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                                                        <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        {...(errors.confirmPassword && { error: true, helperText: errors.confirmPassword.message })}
                                    />
                                )}
                            />
                            <Button fullWidth variant='contained' type='submit'>
                                ثبت رمز عبور جدید
                            </Button>
                            <Typography className='flex justify-center items-center' color='primary'>
                                <Link to="/login" className='flex items-center gap-1.5'>
                                    <DirectionalIcon
                                        ltrIconClass='tabler-chevron-left'
                                        rtlIconClass='tabler-chevron-right'
                                        className='text-xl'
                                    />
                                    <span>بازگشت به صفحه ورود</span>
                                </Link>
                            </Typography>
                        </form>
                    </CardContent>
                </Card>
            </AuthIllustrationWrapper>
        </div>
    )
}

export default ResetPassword
