import React from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

// Third-party Imports
import classnames from 'classnames'
import { Icon } from '@iconify/react/dist/iconify.js'

// Components Imports
import CustomAvatar from '../../../../component/avatar/CustomAvatar'

function CardHorizontal(props) {
    // Props
    const { stats, avatarIcon, avatarColor, title, avatarSkin, avatarSize, avatarIconSize } = props
    return (
        <Card className='bs-full'>
            <CardContent>
                <div className='flex items-center flex-wrap gap-2 justify-between'>
                    <div className='flex flex-col gap-x-4 gap-y-0.5'>
                        <Typography variant='h5'>{stats}</Typography>
                        <Typography variant='subtitle1' color='text.secondary'>
                            {title}
                        </Typography>
                    </div>
                    <CustomAvatar variant='rounded' color={avatarColor} skin={avatarSkin} size={avatarSize}>
                        <Icon icon={avatarIcon} fontSize={avatarIconSize} />
                    </CustomAvatar>
                </div>
            </CardContent>
        </Card>
    )
}

export default CardHorizontal