import React from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'
import { useNavigate } from 'react-router-dom'

// Api Imports 
import { emailOtpReq } from '../../../api/privateApi'

function ValidateEmail() {

    const { user, updateSettings } = useSettings();

    const navigate = useNavigate();

    const emailOtp = async () => {
        try {
            updateSettings({ isLoading: true })
            await emailOtpReq()
            updateSettings({ isLoading: false })
            navigate('/verify-email')
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    return (
        <Card>
            <CardContent>
                <div className=''>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            {user?.is_email_validated ? (
                                <Chip label='احراز هویت شده' color='success' variant='tonal' />
                            ) : (
                                <Chip label='احراز هویت نشده' color='error' variant='tonal' />
                            )
                            }
                        </Grid>
                        <Grid item xs={12} md={6} dir='ltr'>
                            <Typography variant='h5'>{user.email}</Typography>
                        </Grid>
                    </Grid>
                </div>
                {!user?.is_email_validated &&
                    (
                        <Typography className='!mt-6' severity='info'>در صورت احراز هویت ایمیل خود یک ساعت اعتبار رایگان دریافت کنید</Typography>
                    )
                }
                {!user?.is_email_validated &&
                    (
                        <div className='flex justify-center mt-6'>
                            <Button variant='contained' onClick={emailOtp}>احراز هویت ایمیل</Button>
                        </div>

                    )
                }
            </CardContent>
        </Card >
    )
}

export default ValidateEmail