import React, { useState } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import ChangePassword from './chane_password/ChangePassword'
import ValidateEmail from './validate_email/ValidateEmail'

Profile.path = "/profile"
function Profile() {

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6}>
        <ValidateEmail />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChangePassword />
      </Grid>
    </Grid>
  )
}

export default Profile