import React, { useState } from 'react'

// MUI Imports
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

// Component Imports
import Logo from '../../../component/shared/logo/Logo'
import SidebarMenu from '../sidebar_menu/SidebarMenu';

// Third-party imports
import { Icon } from '@iconify/react';

const drawerWidth = 250;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 42px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function PermanentSidebarDrawer() {

    const [open, setOpen] = useState(true);
    const [onClosing, setOnClosing] = useState(false);

    const handleDrawerOpen = () => {
        if (!open && !onClosing) {
            setOpen(true);
        }
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOnClosing(true);
        setTimeout(() => {
            setOnClosing(false)
        }, 300)
    };

    return (
        <div className='shadow-lg'>
            <Drawer variant="permanent" open={open} onMouseEnter={handleDrawerOpen}>
                <DrawerHeader>
                    <div className='flex justify-between items-center w-full px-4'>
                        <Logo hiddenText={!open} />
                        <Icon className='cursor-pointer' icon='mi:close' style={{ fontSize: '24px' }} onClick={handleDrawerClose} />
                    </div>
                </DrawerHeader>
                <Divider />
                <SidebarMenu isDrawerOpen={open} />
            </Drawer>
        </div>
    )
}

export default PermanentSidebarDrawer