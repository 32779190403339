import Providers from "./contexts/Providers";
import AuthLayout from './layout/auth/AuthLayout';
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>تبدیل صدا به متن فارسی</title>
          <link rel="canonical" href="https://begobegam.com/" />
        </Helmet>
        <Providers>
          <AuthLayout />
        </Providers>
      </HelmetProvider>

    </>
  );
}

export default App;
