// MUI Imports
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'

const TablePaginationComponent = ({ table, page, count, setPage }) => {
  return (
    <div className='flex justify-between items-center flex-wrap pli-6 border-bs bs-auto plb-[12.5px] gap-2'>
      <Typography color='text.disabled'>
        {`نمایش ${table.getFilteredRowModel().rows.length === 0
          ? 0
          : (page - 1) * table.getState().pagination.pageSize + 1
          }
        تا ${Math.min(page * table.getState().pagination.pageSize, count)} از ${count} مورد`}
      </Typography>
      <Pagination
        dir='rtl'
        shape='rounded'
        color='primary'
        variant='tonal'
        count={Math.ceil(count / table.getState().pagination.pageSize)}
        page={page}
        onChange={(_, page) => {
          table.setPageIndex(page - 1)
          setPage(page)
        }}
        showFirstButton
        showLastButton
      />
    </div>
  )
}

export default TablePaginationComponent
