import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import LandingPage from '../../pages/landing_page/LandingPage'
import Signup from '../../pages/authenticate/signup/Signup'
import Signin from '../../pages/authenticate/signin/Signin'
import ForgotPassword from '../../pages/authenticate/forgot_password/ForgotPassword'
import ResetPassword from '../../pages/authenticate/reset_password/ResetPassword'

function AuthRoutes() {
    return (
        <Routes>
            <Route path={LandingPage.path} element={<LandingPage />} />
            <Route path={Signin.path} element={<Signin />} />
            <Route path={Signup.path} element={<Signup />} />
            <Route path={ForgotPassword.path} element={<ForgotPassword />} />
            <Route path={ResetPassword.path} element={<ResetPassword />} />
            <Route path="*" element={<Navigate to={LandingPage.path} />} />
        </Routes>
    )
}

export default AuthRoutes