// React Imports
import { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

// Component Imports
import Logo from '../../../component/shared/logo/Logo'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

// Hook Imports 
import { useSettings } from '../../../hooks/useSetting'

// Third-party Imports
import { Link } from 'react-router-dom'
import { Icon, _api } from '@iconify/react/dist/iconify.js'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Controller, useForm } from 'react-hook-form'
import { object, minLength, string, email } from 'valibot'
import { toast } from 'react-toastify'

// Styled Component Imports
import AuthIllustrationWrapper from '../../../component/shared/AuthIllustrationWrapper/AuthIllustrationWrapper'

// Api Imports
import { register } from '../../../api/publicApi'


const schema = object({
  email: string([minLength(1, 'لطفا ایمیل خود را وارد کنید.'), email('ایمیل وارد شده صحیح نمیباشد!')]),
  password: string([
    minLength(1, 'لطفا رمز عبور را وارد کنید.'),
    minLength(6, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
  ]),
  retypePassowrd: string([
    minLength(1, 'لطفا رمز عبور را وارد کنید.'),
    minLength(6, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
  ]),
})

Signup.path = "/register"
function Signup() {

  // States
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isPasswordShown1, setIsPasswordShown1] = useState(false)
  const [errorState, setErrorState] = useState(null)
  const [loading, setLoading] = useState(false)

  // Hooks
  const { updateUser } = useSettings();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: valibotResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      retypePassowrd: ''
    }
  })

  const onSubmit = async (data) => {
    try {
      if (data.password != data.retypePassowrd) {
        return toast.error("رمز عبور اشتباه است")
      }
      setLoading(true)

      let registerRes = await register({
        email: data.email,
        password: data.password
      })

      localStorage.setItem('access_token', registerRes.access_token);
      localStorage.setItem('refresh_token', registerRes.refresh_token);
      updateUser({ ...registerRes.user, isLogin: true })
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }


    // toast.success("Form Submitted");
    // updateUser({ isLogin: true })
  }

  return (
    <div className='h-screen flex justify-center items-center'>
      <AuthIllustrationWrapper>
        <Card className='flex flex-col sm:is-[450px]'>
          <CardContent className='sm:!p-12'>
            <div className='flex justify-center mbe-6'>
              <Logo />
            </div>
            <div className='flex flex-col gap-1 mbe-6'>
              <Typography variant='h4'>خوش آمدید 🚀</Typography>
              <Typography>برای ثبت نام فرم زیر را پر کنید</Typography>
            </div>
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    autoFocus
                    fullWidth
                    type='email'
                    label='ایمیل'
                    placeholder='ایمیل خود را وارد کنید'
                    onChange={e => {
                      field.onChange(e.target.value)
                      errorState !== null && setErrorState(null)
                    }}
                    {...((errors.email || errorState !== null) && {
                      error: true,
                      helperText: errors?.email?.message || errorState?.message[0]
                    })}
                  />
                )}
              />
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label='رمز عبور'
                    placeholder='············'
                    id='signup-password'
                    type={isPasswordShown ? 'text' : 'password'}
                    onChange={e => {
                      field.onChange(e.target.value)
                      errorState !== null && setErrorState(null)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                            <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...(errors.password && { error: true, helperText: errors.password.message })}
                  />
                )}
              />
              <Controller
                name='retypePassowrd'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    label='تکرار رمز عبور'
                    placeholder='············'
                    id='signup-retype-password'
                    type={isPasswordShown1 ? 'text' : 'password'}
                    onChange={e => {
                      field.onChange(e.target.value)
                      errorState !== null && setErrorState(null)
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton edge='end' onClick={() => setIsPasswordShown1(show => !show)} onMouseDown={e => e.preventDefault()}>
                            <Icon icon={isPasswordShown1 ? 'mdi:eye' : 'mdi:eye-off'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    {...(errors.retypePassowrd && { error: true, helperText: errors.retypePassowrd.message })}
                  />
                )}
              />
              <Button fullWidth variant='contained' type='submit' disabled={loading}>
                ثبت نام
                {loading && <CircularProgress className='mr-4' size={20} color='inherit' />}
              </Button>
              <div className='flex justify-center items-center flex-wrap gap-2'>
                <Typography>حساب کاربری دارید؟</Typography>
                <Typography component={Link} to="/login" color='primary'>
                  از اینجا وارد شوید
                </Typography>
              </div>
            </form>
            {/* <Backdrop open={true} className='absolute text-white z-[cal(var(--mui-zIndex-mobileStepper)-1)]'>
              <CircularProgress color='inherit' />
            </Backdrop> */}
          </CardContent>
        </Card>
      </AuthIllustrationWrapper>
    </div>
  )
}

export default Signup
