import React, { useState, useEffect } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';

// Component Imports
import CustomTextField from '../../component/custom_text_field/CustomTextField';
import DaramRoshKarMikonam from '../../component/shared/daram_rosh_kar_mikonam/DaramRoshKarMikonam';
import { Button } from '@mui/material';

// Third-party Imports
import classnames from 'classnames';

// Hook Imports 
import { useSettings } from '../../hooks/useSetting'
import useSWR from 'swr'

// Api Imports 
import { getPlansListReq } from '../../api/privateApi';

CreditPlans.path = "/credit-plans"
function CreditPlans() {

  // States
  const [number, setNumber] = useState(1)
  const [open, setOpen] = useState(false)

  const [totalPrice, setTotalPrice] = useState(255000)

  const [plans, setPlans] = useState([])

  // Hooks
  const { updateSettings } = useSettings();

  useEffect(() => {
    getPlans()
  }, [])

  useEffect(() => {
    let selectedPlan = null
    plans?.map((plan) => {
      if (plan.isSelected) {
        selectedPlan = plan
        return
      }
    })
    if (number > 0 && selectedPlan) {
      setTotalPrice(number * selectedPlan.price)
    } else {
      setTotalPrice(0)
    }
  }, [number, plans])

  const getPlans = async () => {
    try {
      updateSettings({ isLoading: true })
      let res = await getPlansListReq()
      updateSettings({ isLoading: false })
      setPlans(res)
    } catch (err) {
      updateSettings({ isLoading: false })
    }
  }

  const handleClickOnPlan = (_id) => {
    let newPlans = [...plans]
    newPlans.map((plan) => {
      if (plan._id == _id) {
        plan.isSelected = true
      } else {
        plan.isSelected = false
      }
    });
    setPlans(newPlans)
  }

  return (
    <>
      <Card>
        <CardContent className='flex flex-col items-center gap-3'>
          <Typography variant='h5'>
            افزایش اعتبار
          </Typography>
          <Typography>
            بگو بگم یک نرم افزار هوش مصنوعی برای تبدیل فایل صوتی و به متن تایپ شده فارسی است.
          </Typography>
          <Grid spacing={6} container className='pt-6'>
            <Grid item xs={12} md={8}>
              <div className='w-full min-h-[400px]'>
                <Grid container spacing={4}>
                  {plans?.map((plan, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <Card
                        className={classnames(
                          'cursor-pointer border',
                          {
                            'border-primary': plan.isSelected
                          }
                        )}
                        onClick={() => handleClickOnPlan(plan._id)}>
                        <CardContent>
                          <Typography variant='h5'>{plan.title}</Typography>
                          <Typography>{plan.price.toLocaleString()} تومان</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className='flex flex-col items-start gap-4'>
                <CustomTextField value={number} onChange={(e) => setNumber(e.target.value)} type='number' label='تعداد' />
                {totalPrice > 0 && <Typography variant='h5'>{totalPrice.toLocaleString()} تومان</Typography>}
                <Button variant='contained' disabled={totalPrice === 0} onClick={() => setOpen(true)}>پرداخت</Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DaramRoshKarMikonam open={open} setOpen={setOpen} />
    </>
  )
}

export default CreditPlans