import React from 'react'

// MUI Imports
import Button from '@mui/material/Button';

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// Api Imports
import { newTaskReq } from '../../../api/privateApi';

function FileUploader({ onFileSelected }) {

    const onSelectFileHandler = (e) => {
        const { files } = e.target;
        if (files && files.length) {
            let file = files[0];
            const filename = files[0].name;
            onFileSelected(file)
        }
    }

    return (
        <Button
            variant='contained'
            component="label">
            <Icon
                className='ml-2'
                icon='iconoir:plus'
                fontSize={24}
            />
            تسک جدید
            <input
                type="file"
                hidden
                onChange={onSelectFileHandler}
            />
        </Button>
    )
}

export default FileUploader