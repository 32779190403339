import React, { useMemo, useState, useEffect } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import {
  useReactTable,
  flexRender,
  createColumnHelper,
  getCoreRowModel
} from '@tanstack/react-table'
import classnames from 'classnames'

// Hook Imports
import { useSettings } from '../../hooks/useSetting'

// Component Imports
import AddUserDialog from './add_user_dialog/AddUserDialog'

// Style Imports 
import tableStyles from '../../styles/table.module.css'

// Api Imports
import { getTelegramBotUserListReq, removeTelegramBotUserReq } from '../../api/privateApi'

// Column Definitions
const columnHelper = createColumnHelper()

TelegramBot.path = "/telegram-bot"
function TelegramBot() {

  // State
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [data, setData] = useState([])

  // Hooks 
  const { updateSettings } = useSettings()

  useEffect(() => {
    getTelegramBotUserList()
  }, [])

  const columns = useMemo(() => [
    columnHelper.accessor('full_name', {
      header: 'نام کاربر',
      cell: ({ row }) => (
        <div>
          <Typography>
            <span dir="ltr">
              {row.original.full_name}
            </span>
          </Typography>
        </div>
      )
    }),
    columnHelper.accessor('username', {
      header: 'آیدی',
      cell: ({ row }) => (
        <div>
          <Typography>
            {row.original.username}
          </Typography>
        </div>
      )
    }),
    columnHelper.accessor('action', {
      header: 'عملیات',
      cell: ({ row }) => (
        <div className='flex items-center mr-2' >
          <Icon className='hover:text-primary cursor-pointer' icon='ph:trash-fill' fontSize={22} onClick={() => removeTelegramBotUser(row.original._id)} />
        </div>
      )
    }),
  ], [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const getTelegramBotUserList = async () => {
    try {
      updateSettings({ isLoading: true })
      let users = await getTelegramBotUserListReq();
      setData(users)
      updateSettings({ isLoading: false })
    } catch (err) {
      updateSettings({ isLoading: false })
    }
  }

  const removeTelegramBotUser = async (_id) => {
    try {
      updateSettings({ isLoading: true })
      await removeTelegramBotUserReq({ _id });
      getTelegramBotUserList();
    } catch (err) {
      updateSettings({ isLoading: false })
    }
  }

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant='h5'>ربات تلگرام</Typography>
          <Typography className='!mt-4'>
            با استفاده از ربات تلگرام شما می‌توانید به راحتی محتوای صوتی خود را به متن فارسی تبدیل کنید. برای استفاده از ربات تلگرام باید آیدی تگرام کسانی را که می‌خواهید در جدول زیر اضافه کنید،بعد از آن آیدی های اضافه شده دسترسی به استفاده از ربات تلگرام را خواهند داشت.
          </Typography>
          <Typography className='!mt-2'>
            نکته: شما تنها میتوانید ۵ نفر از دوشتان خود را اضافه کنید.
          </Typography>
          <Typography className='!mt-4'>آدرس ربات تلگرام:</Typography>
          <div className='flex justify-end'>
            <Typography
              className='!text-sky-600'
              component={Link}
              to="https://t.me/mehrdad_speech_to_text_bot"
              target="_blank"
              rel="noopener noreferrer" >
              https://t.me/mehrdad_speech_to_text_bot
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card className='mt-8'>
        <CardContent>
          <div>
            <div className='flex justify-end'>
              <Button variant='contained' onClick={() => setAddDialogOpen(true)}>
                <Icon
                  className='ml-2'
                  icon='iconoir:plus'
                  fontSize={24}
                />
                افزودن
              </Button>
            </div>
            <div className='mt-4'>
              <table className={tableStyles.table}>
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id}>
                          {header.isPlaceholder ? null : (
                            <>
                              <div
                                className={classnames({
                                  'flex items-center': header.column.getIsSorted(),
                                  'cursor-pointer select-none': header.column.getCanSort()
                                })}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: <i className='tabler-chevron-up text-xl' />,
                                  desc: <i className='tabler-chevron-down text-xl' />
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            </>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {table.getFilteredRowModel().rows.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={table.getVisibleFlatColumns().length} className='text-center'>
                        No data available
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {table
                      .getRowModel()
                      .rows.slice(0, table.getState().pagination.pageSize)
                      .map(row => {
                        return (
                          <tr key={row.id} className={classnames({ selected: row.getIsSelected() })}>
                            {row.getVisibleCells().map(cell => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                          </tr>
                        )
                      })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </CardContent>
      </Card>
      <AddUserDialog open={addDialogOpen} setOpen={setAddDialogOpen} getTelegramBotUserList={getTelegramBotUserList} />
    </div>

  )
}

export default TelegramBot