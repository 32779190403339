import React, { useEffect, useRef } from 'react'
import VuexyLogo from '../../svg/Logo';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import themeConfig from '../../../config/themeConfig';

const LogoText = styled.span`
  font-size: 1.375rem;
  line-height: 1.09091;
  font-weight: 700;
  letter-spacing: 0.25px;
  color: var(--mui-palette-text-primary);
  transition: ${({ transitionDuration }) =>
        `margin-inline-start ${transitionDuration}ms ease-in-out, opacity ${transitionDuration}ms ease-in-out`};
`

function Logo({ hiddenText }) {

    const logoTextRef = useRef(null);
    useEffect(() => {
        if (hiddenText) {
            logoTextRef.current?.classList.add('hidden')
        } else {
            logoTextRef.current.classList.remove('hidden')
        }
    }, [hiddenText])

    return (
        <Link to='/' className='flex items-center no-underline'>
            <VuexyLogo className='text-2xl text-primary' />
            <LogoText
                className='mr-2'
                ref={logoTextRef}
                isHovered={false}
                transitionDuration={1000}
            >
                {themeConfig.templateName}
            </LogoText>
        </Link>
    )
}

export default Logo