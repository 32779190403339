import React from 'react'
import { Drawer } from '@mui/material'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Link } from 'react-scroll'

const items = [
    {
        title: 'خانه',
        to: 'home'
    },
    {
        title: 'قابلیت ها',
        to: 'features'
    },
    {
        title: 'سوالات متداول',
        to: 'faq'
    },
    {
        title: 'تماس با ما',
        to: 'contactUs'
    },
]

function LandingPageDrawer({ open, setOpen }) {
    return (
        <Drawer
            anchor='left'
            open={open}
            onClose={() => setOpen(false)}
            ModalProps={{ keepMounted: true }}
            sx={{ '& .MuiDrawer-paper': { width: ['80%', 400] } }}
        >
            <div className='flex justify-end p-4'>
                <Icon className='text-textSecondary' icon='carbon:close' style={{ fontSize: '24px' }} onClick={() => setOpen(false)} />
            </div>
            <div className='flex flex-col gap-4 mr-4'>
                {items.map((item, index) => (
                    <Link
                        key={index}
                        className='font-bold cursor-pointer hover:text-primary'
                        activeClass="active"
                        to={item.to}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        onClick={() => setOpen(false)}
                    >{item.title}</Link>
                ))}
            </div>
        </Drawer>
    )
}

export default LandingPageDrawer