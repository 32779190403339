import React, { useEffect, useState } from 'react'

// MUI Imports
import classnames from 'classnames'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LandingReviewCard from './LandingReviewCard'
import Slider from "react-slick";
import useMediaQuery from '../../../hooks/useMediaQuery'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const comments = [
    {
        logo: '/images/pages/landingpage/logo-1.png',
        comment: `من کارم تولید محتوا برای جامعه ناشنوایان هستش قبل از این باید ساعت‌ها وقت می‌ذاشتم تا متن‌ها را تایپ کنم اما الان به راحتی می‌تونم زیرنویس‌هامو اضافه کنم`,
        rate: 5,
        avatar: '/images/avatars/1.png',
        name: 'تینا روشنفکر',
        position: 'CEO of Airbnb',
    },
    {
        logo: '/images/pages/landingpage/logo-2.png',
        comment: `سلام از دو تا سایت قبلا استفاده کرده بودم ولی بعد از مدتی سطح کیفیت خدماتشون خیلی پایین اومد می‌خواستم ازتون تشکر کنم که تو این مدت کیفیتتون حتی بیشتر هم شده`,
        rate: 5,
        avatar: '/images/avatars/2.png',
        name: 'محسن کاظمی',
        position: 'Founder of Hubspot',
    },
    {
        logo: '/images/pages/landingpage/logo-3.png',
        comment: `سلام قیمت‌هاتون با توجه به کیفیتی که کار شما داره بسیار منصفانه هستش دست شما درد نکنه`,
        rate: 4,
        avatar: '/images/avatars/3.png',
        name: 'علی نعمتزاده',
        position: 'Design Lead at Dribbble',
    },
    {
        logo: '/images/pages/landingpage/logo-4.png',
        comment: `از وقتی که ربات تلگرام رو اضافه کردین خیلی کارم راحت‌تر شده قبلاً باید فایل رو بارگذاری می‌کردم اما الان خیلی راحت صدامو ضبط می‌کنم و متنو دریافت می‌کنم`,
        rate: 4.5,
        avatar: '/images/avatars/4.png',
        name: 'شیما فتاحی',
        position: 'Founder of Continental',
    },
    {
        logo: '/images/pages/landingpage/logo-5.png',
        comment: `من خودم کارم نرم‌افزار هستش و برای یک برنامه برگزاری جلسات از سرویس شما ای پی آی خریداری کردم سرعت ای پی آی شما بسیار خوبه و کارتون حرفه‌ای هستش ممنون`,
        rate: 4.5,
        avatar: '/images/avatars/6.png',
        name: 'حسن حاج عبدالله',
        position: 'Founder of Continental',
    },    
]

function LandingReviews({ mode }) {
    const [slickSettings, setSlickSettings] = useState({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    });

    const isBreakPoint1Reached = useMediaQuery('1200px');
    const isBreakPoint2Reached = useMediaQuery('990px');

    useEffect(() => {
        if (isBreakPoint1Reached && isBreakPoint2Reached) {
            setSlickSettings({ ...slickSettings, slidesToShow: 1 })
        } else if (isBreakPoint1Reached && !isBreakPoint2Reached) {
            setSlickSettings({ ...slickSettings, slidesToShow: 2 })
        } else if (!isBreakPoint1Reached && !isBreakPoint2Reached) {
            setSlickSettings({ ...slickSettings, slidesToShow: 3 })
        }

    }, [isBreakPoint1Reached, isBreakPoint2Reached])

    return (
        <div className={classnames(
            'mt-20 rounded-t-[3.75rem] pt-24 pb-12',
            {
                'bg-[#F8F7FA]': mode == 'light',
                'bg-[#1e2130]': mode == 'dark',
            }
        )} dir='ltr'>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    <div className='px-4 sm:px-2'>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <div className='w-full  h-full flex flex-col justify-center items-start pl-4'>
                                    <div className="text-center mb-3 pb-1">
                                        <Chip label='نظرات مشتریان واقعی' color='primary' variant='tonal' />
                                    </div>
                                    <h3 className="text-center mb-1">
                                        <span className="relative font-bold z-1 text-2xl">
                                            آنچه مردم می گویند
                                            <img
                                                src="/images/pages/landingpage/section-title-icon.png"
                                                alt="laptop charging"
                                                className="h-full w-[120%] top-[9px] left-[-12%] absolute object-contain bottom-0 z-1" />
                                        </span>
                                    </h3>
                                    <Typography className='!mt-4' variant='h6'>
                                        ببینید مشتریان ما در مورد تجربه خود چه می گویند
                                    </Typography>
                                </div>
                            </Grid>


                            <Grid item xs={12} sm={6} md={8} lg={9}>
                                <div className='slider-container px-2'>
                                    <Slider {...slickSettings}>
                                        {comments.map((comment, index) => (
                                            <LandingReviewCard key={index} comment={comment} />
                                        ))}
                                    </Slider>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {/* <Divider className='!mt-12' />
            <div className='w-full bg-gray-100 min-h-[160px]'>

            </div> */}
        </div>
    )
}

export default LandingReviews