import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

const features = [
    {
        title: 'دقت بالا در تشخیص',
        desc: 'دقت بالای تایپ صوتی با تبدیل ۹۸٪ گفتار شما ، در صورت انتخاب میکروفون مناسب.',
        icon: 'bi:laptop'
    },
    {
        title: 'تبدیل همزمان صدا به متن',
        desc: 'سرعت بالا در تبدیل گفتار به نوشتار ، همزمان با سخن گفتن در محیط بدون سروصدا و اینترنت مطلوب.',
        icon: 'ion:rocket-outline'
    },
    {
        title: 'یادگیری از گفتار شما',
        desc: 'یادگیری از گفتار شما و افزایش دقت در تبدیل با استفاده طولانی مدت از نرم افزار.',
        icon: 'hugeicons:ai-brain-03'
    },
    {
        title: 'امکان استفاده از API',
        desc: 'برای اولین بار برای زبان فارسی در کشور امکان ارائه API جهت استفاده در نرم‌افزارهای شما و یا سازمان‌ها',
        icon: 'ic:round-api'
    },
    {
        title: 'پشتیبانی عالی',
        desc: 'پشتیبانی حرفه‌ای همکاران مجرب ما آماده پاسخگویی به مشکلات احتمالی و راهنمایی ۲۴ ساعته شما کاربران گرامی هستند.',
        icon: 'fluent:person-support-28-regular'
    },
    {
        title: 'ربات تلگرام',
        desc: 'امکان استفاده از ربات تلگرام برای تبدیل صدای شما به نوشتار',
        icon: 'hugeicons:telegram'
    },
]

function UsefulFeature() {
    return (
        <div className='flex flex-col px-4 text-center' >
            <div className="text-center mb-3 pb-1">
                <Chip label='ویژگی‌های کاربردی' color='primary' variant='tonal' />
            </div>
            <h3 className="text-center mb-1">
                <span className="relative font-bold z-1 text-2xl">
                    هر آنچه که که نیاز دارید
                    <img
                        src="/images/pages/landingpage/section-title-icon.png"
                        alt="laptop charging"
                        className="h-full w-[120%] top-[10px] left-[-12%] absolute object-contain bottom-0" />
                </span>
                <span className='font-normal text-2xl'> برای تسریع در نوشتار</span>
            </h3>
            <Typography className='!text-textSecondary' variant='h6'>
                ویژگی‌های کاربردی و مفید که می‌توانید هم در پروژه‌های شخصی و هم در سازمان‌ها از آنها استفاده کنید.
            </Typography>
            <Grid container spacing={12} className='!mt-8'>
                {features.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div className="flex justify-center items-center mt-6">
                            <div className='max-w-[19.25rem] text-center'>
                                <div className="text-center">
                                    {/* <img src={item.icon} alt="laptop charging" /> */}
                                    <Icon icon={item.icon} fontSize={54} className='text-primary' />
                                </div>
                                <Typography variant='h5'>{item.title}</Typography>
                                <Typography className='!text-textSecondary' variant='h6'>{item.desc}</Typography>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div >
    )
}

export default UsefulFeature