import axios from "axios";
import checkResponse from '../utils/check_response'

const publicApi = axios.create({
    baseURL: "https://api.begobegam.com"
})

export const publicUrlEndpoint = '/api'

export const register = async (body) => {
    const response = await publicApi.post(`${publicUrlEndpoint}/auth/register`, body)
    let data = checkResponse(response.data)
    return data
}

export const loginReq = async (body) => {
    const response = await publicApi.post(`${publicUrlEndpoint}/auth/login`, body)
    let data = checkResponse(response.data)
    return data
}

export const sendResetPasswordEmailReq = async (body) => {
    const response = await publicApi.post(`${publicUrlEndpoint}/auth/send_reset_password_email`, body)
    let data = checkResponse(response.data)
    return data
}

export const validateResetTokenReq = async (body) => {
    const response = await publicApi.post(`${publicUrlEndpoint}/auth/validate_reset_token`, body)
    let data = checkResponse(response.data)
    return data
}

export const resetPasswordWithTokenReq = async (body) => {
    const response = await publicApi.post(`${publicUrlEndpoint}/auth/reset_password`, body)
    let data = checkResponse(response.data)
    return data
}