import React, { useState, createContext } from 'react'
import themeConfig from '../../config/themeConfig'
import primaryColorConfig from '../../config/primaryColorConfig'
import { useObjectCookie } from '../../hooks/useObjectCookie'

export const SettingsContext = createContext(null)

export const SettingsProvider = (props) => {

    const initialSettings = {
        mode: themeConfig.mode,
        systemMode: themeConfig.mode == 'system' ? 'light' : themeConfig.mode,
        primaryColor: primaryColorConfig[0].main,
        skin: themeConfig.skin,
        isDrawerOpen: false,
        drawer: 'permanent',
        isLoading: false,
    }

    const initialUser = {
        isLogin: false
    }

    // Cookies
    const [settingsCookie, updateSettingsCookie] = useObjectCookie(
        themeConfig.settingsCookieName,
        initialSettings
    )
    const [userCookie, updateUserCookie] = useObjectCookie(
        themeConfig.userCookieName,
        initialUser
    )

    // State
    const [_settingsState, _updateSettingsState] = useState(
        JSON.stringify(settingsCookie) !== '{}' ? settingsCookie : initialSettings
    );
    const [_userState, _updateUserState] = useState(
        JSON.stringify(userCookie) !== '{}' ? userCookie : initialUser
    );

    const updateSettings = (settings) => {
        _updateSettingsState(prev => {
            const newSetting = { ...prev, ...settings }
            updateSettingsCookie(newSetting);
            return newSetting;
        })
    }

    const updateUser = (user) => {
        _updateUserState(prev => {
            const newUser = { ...prev, ...user }
            updateUserCookie(newUser)
            return newUser;
        })
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: _settingsState,
                updateSettings,
                user: _userState,
                updateUser
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    )
}