import React, { useEffect, useState } from 'react'

// Mui Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

// Component Imports
import Microphone from './microphone/Microphone'
import CustomTextField from '../../component/custom_text_field/CustomTextField'
import MenuItem from '@mui/material/MenuItem'
import EditorToolbar from './conversion_editor/editor_toolbar/EditorToolbar'

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import { Underline } from '@tiptap/extension-underline'
import { Placeholder } from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'

InstantConversion.path = "/instant_conversion"
function InstantConversion() {

    const [transcript, setTranscript] = useState('');
    const [interimTranscript, setInterimTranscript] = useState('');
    const [language, setLanguage] = useState('fa-IR')

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Placeholder.configure({
                placeholder: 'Write something here...'
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                defaultAlignment: 'right',
            })
        ],
        editorProps: {
            attributes: {
                class: 'focus:outline-none w-full',
            },
        },
        content:
            transcript !== null && transcript !== void 0
                ? transcript
                : `
          <p>
            This is a radically reduced version of tiptap. It has support for a document, with paragraphs and text. That's it. It's probably too much for real minimalists though.
          </p>
          <br />
          <p>
            The paragraph extension is not really required, but you need at least one node. Sure, that node can be something different.
          </p>
        `,
        onUpdate({ editor }) {
            console.log(editor.getJSON());
            setTranscript(editor?.getText())
        }
    })

    const updateTranscript = (sentence) => {
        let newTranscript = transcript

        if (transcript.charAt(transcript.length - 1) != ' ') {
            newTranscript = newTranscript + ' '
        }
        setTranscript(newTranscript + sentence);
        editor?.commands.setContent(newTranscript + sentence)
    }

    const updateIntermTranscript = (interimTranscript) => {
        setInterimTranscript(interimTranscript)
    }

    return (
        <div>
            <div className='!pt-12 flex items-center justify-center gap-4'>
                <Microphone
                    language={language}
                    updateTranscript={updateTranscript}
                    updateIntermTranscript={updateIntermTranscript} />
            </div>
            <div className='text-center mt-4 min-h-8'>
                <p>{interimTranscript}</p>
            </div>
            <div className='mt-2'>
                <Card>
                    <CardContent>
                        <div className='py-2 flex justify-end' >
                            <CustomTextField
                                className='!text-sm'
                                size='small'
                                select
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            >
                                <MenuItem value='fa-IR'>
                                    <div className='flex items-center gap-2 px-2'>
                                        <Icon icon='twemoji:flag-iran' fontSize={22} />
                                        <Typography>فارسی</Typography>
                                    </div>
                                </MenuItem>
                                <MenuItem value='en-US'>
                                    <div className='flex items-center gap-2 px-2'>
                                        <Icon icon='twemoji:flag-united-states' fontSize={22} />
                                        <Typography>انگلیسی</Typography>
                                    </div>
                                </MenuItem>
                            </CustomTextField>
                        </div>
                        <div className='border rounded-md'>
                            <EditorToolbar editor={editor} />
                            <Divider />
                            <EditorContent editor={editor} className='bs-[200px] overflow-y-auto flex p-3' />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default InstantConversion