import { toast } from "react-toastify"

const checkResponse = (data) => {
    if (data && data?.setting.success == 1) {
        return data.data
    } else {
        let err = data?.setting.message || "عملیات موفقیت آمیز نبود. لطفا دوباره امتحان کنید!!!"
        toast.error(err)
        throw new Error("fuck")
    }
}

export default checkResponse