import React from 'react'

// MUI Imports
import Button from '@mui/material/Button'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'
import { useNavigate } from 'react-router-dom'

// Component Imports
import ModeDropDown from '../../../component/shared/mode_drop_down/ModeDropDown'
import UserDropdown from './user_dropdown/UserDropdown'

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

import displaySecond from '../../../utils/second_to_hms'
import { Typography } from '@mui/material'


function Navbar() {

    // Hooks
    const { updateSettings, user } = useSettings()
    const navigate = useNavigate()

    const handleOpenDrawer = () => {
        updateSettings({ isDrawerOpen: true })
    }

    return (
        <div className='sticky top-5 z-50'>
            <div className='bg-backgroundPaper py-3 px-4 md:px-6 rounded-sm shadow-lg'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-3'>
                        <Icon
                            icon='tabler-menu-2'
                            className='cursor-pointer flex lg:hidden'
                            fontSize={24}
                            onClick={handleOpenDrawer}
                        />
                        <Button
                            variant='contained'
                            className='!hidden lg:!flex'
                            onClick={() => navigate('/credit-plans')}>
                            <Icon
                                className='ml-2'
                                icon='iconoir:plus'
                                fontSize={24}
                                onClick={handleOpenDrawer}
                            />

                            <Typography className='!text-white'>
                                افزایش اعتبار:
                            </Typography>
                            <Typography className='!text-white !mt-1 !mr-1'>
                                {displaySecond(user.credit)}
                            </Typography>

                        </Button>
                    </div>
                    <div className='flex items-center'>
                        <ModeDropDown />
                        <UserDropdown />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar