// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Rating from '@mui/material/Rating'

import CustomAvatar from '../../../component/avatar/CustomAvatar'
import { Icon } from '@iconify/react/dist/iconify.js'

function LandingReviewCard({ comment }) {

    return (
        <Card className='h-[300px] mx-2' dir="rtl">
            <CardContent className='flex h-full flex-col !justify-between items-start'>
                <Icon icon='ic:outline-rate-review' fontSize={54} className='text-primary' />
                <Typography color='text.secondary' className='!mt-4'>
                    {comment.comment}
                </Typography>
                <div className='flex flex-wrap gap-x-2 gap-y-1 mt-4' >
                    <Rating name='read-only' value={comment.rate} readOnly />
                </div>
                <div className='flex items-center gap-3 mt-4'>
                    <CustomAvatar src={comment.avatar} skin='light' size={34} />
                    <div className='flex flex-col'>
                        <Typography className='font-medium' color='text.primary'>
                            {comment.name}
                        </Typography>
                        {/* <Typography variant='body2'>{comment.position}</Typography> */}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default LandingReviewCard