import React, { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// Component Imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import Logo from '../../../component/shared/logo/Logo'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'
import AuthIllustrationWrapper from '../../../component/shared/AuthIllustrationWrapper/AuthIllustrationWrapper'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'

// Third-party Imports
import { Link } from 'react-router-dom'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Controller, useForm } from 'react-hook-form'
import { object, minLength, string, email } from 'valibot'

// Component imports 
import EmailSentDialog from './email_sent_dialog/EmailSentDialog'

// Api imports 
import { sendResetPasswordEmailReq } from '../../../api/publicApi'

const schema = object({
    email: string([minLength(1, 'لطفا ایمیل خود را وارد کنید.'), email('ایمیل وارد شده صحیح نمیباشد!')]),
})

ForgotPassword.path = "/forgot-password"
function ForgotPassword() {

    // States
    const [errorState, setErrorState] = useState(null)
    const [open, setOpen] = useState(false)

    // Hooks 
    const { updateSettings, updateUser } = useSettings()

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: valibotResolver(schema),
        defaultValues: {
            email: '',
        }
    })


    const onSubmit = async (data) => {
        try {
            updateSettings({ isLoading: true })
            await sendResetPasswordEmailReq({ email: data.email })
            updateSettings({ isLoading: false })
            setOpen(true);
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    return (
        <div className='h-screen flex justify-center items-center'>
            <AuthIllustrationWrapper>
                <Card className='flex flex-col sm:is-[450px]'>
                    <CardContent className='sm:!p-12'>
                        <div className='flex justify-center mbe-6'>
                            <Logo />
                        </div>
                        <div className='flex flex-col gap-1 mbe-6'>
                            <Typography variant='h4'>فراموشی رمز عبور 🔒</Typography>
                            <Typography>ایمیل خود را وارد کنید و ما دستورالعمل هایی را برای بازنشانی رمز عبور برای شما ارسال می کنیم</Typography>
                        </div>
                        <form noValidate autoComplete='off' className='flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name='email'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomTextField
                                        {...field}
                                        autoFocus
                                        fullWidth
                                        type='email'
                                        label='ایمیل'
                                        placeholder='ایمیل خود را وارد کنید'
                                        onChange={e => {
                                            field.onChange(e.target.value)
                                            errorState !== null && setErrorState(null)
                                        }}
                                        {...((errors.email || errorState !== null) && {
                                            error: true,
                                            helperText: errors?.email?.message || errorState?.message[0]
                                        })}
                                    />
                                )}
                            />
                            <Button fullWidth variant='contained' type='submit'>
                                ارسال لینک
                            </Button>
                            <Typography className='flex justify-center items-center' color='primary'>
                                <Link to="/login" className='flex items-center gap-1.5'>
                                    <DirectionalIcon
                                        ltrIconClass='tabler-chevron-left'
                                        rtlIconClass='tabler-chevron-right'
                                        className='text-xl'
                                    />
                                    <span>بازگشت به صفحه ورود</span>
                                </Link>
                            </Typography>
                        </form>
                    </CardContent>
                </Card>
            </AuthIllustrationWrapper>
            <EmailSentDialog open={open} setOpen={setOpen} />
        </div>
    )
}

export default ForgotPassword