import React, { useState, useRef } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'

// Hook Imports
import { useSettings } from '../../hooks/useSetting'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// Component Imports
import ApiDocs from './api_docs/ApiDocs'

// Api Imports
import { removeApiKeyReq, generateApiKeyReq } from '../../api/privateApi'
import { toast } from 'react-toastify'

API.path = "/api"
function API() {
    // State
    const [open, setOpen] = useState(false)

    // Hooks 
    const { user, updateSettings, updateUser } = useSettings()

    // Refs
    const anchorRef = useRef(null)

    const handleDropdownClose = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e?.target)) {
            return
        }
        setOpen(false)
    }


    const removeApiKey = async () => {
        handleDropdownClose()
        try {
            updateSettings({ isLoading: true })
            let newUser = await removeApiKeyReq()
            updateSettings({ isLoading: false })
            updateUser(newUser)
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    const generateApiKey = async () => {
        try {
            updateSettings({ isLoading: true })
            let newUser = await generateApiKeyReq()
            updateSettings({ isLoading: false })
            updateUser(newUser)
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    const handleClickApiKey = () => {
        if (user.api_key) {
            navigator.clipboard.writeText(user.api_key)
            toast.success("کپی شد.")
        }
    }

    return (
        <div>
            <Card>
                <CardContent>
                    <Typography variant='h5'>
                        API
                    </Typography>
                    <Typography className='!mt-12'>
                        دریافت توکن جهت استفاده از API های سایت
                    </Typography>
                    <div className='mt-4 flex flex-col items-center gap-4 sm:flex-row'>
                        <div className='cursor-pointer min-w-[310px] min-h-[37px] border-[1.5px] border-primary flex justify-center items-center rounded-sm sm:min-w-[400px] font-bold' onClick={handleClickApiKey}>
                            {user.api_key}
                        </div>
                        {user.api_key ? (
                            <>
                                <Button ref={anchorRef} variant='contained' color='error' onClick={() => setOpen(true)}>
                                    حذف
                                </Button>
                                <Popper
                                    open={open}
                                    transition
                                    disablePortal
                                    placement='top'
                                    anchorEl={anchorRef.current}
                                    className='min-is-[240px] !mbs-3 z-[1]'
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Fade
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin: placement === 'bottom-end' ? 'right top' : 'left top'
                                            }}
                                        >
                                            <Paper className='!shadow-lg my-2'>
                                                <ClickAwayListener onClickAway={e => handleDropdownClose(e)}>
                                                    <div className='pt-6 pb-3 px-4'>
                                                        <div className='flex items-center'>
                                                            <Icon icon="ic:sharp-warning" fontSize={22} className='text-red-500' />
                                                            <Typography className='!mt-1 !mr-1' variant='h6'>
                                                                مطمئن هستید؟
                                                            </Typography>
                                                        </div>
                                                        <div className='flex gap-2 mt-3 justify-center'>
                                                            <Button variant='outlined' size='small' color='error' onClick={removeApiKey}>
                                                                بله
                                                            </Button>
                                                            <Button variant='outlined' size='small' color='info' onClick={handleDropdownClose}>
                                                                خیر
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </ClickAwayListener>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </>
                        ) : (
                            <Button variant='contained' onClick={generateApiKey}>
                                دریافت
                            </Button>
                        )}
                    </div>
                </CardContent>
            </Card>
            <ApiDocs />
        </div>
    )
}

export default API