import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'

import CardHorizontal from './car_horizontal/CardHorizontal'

let cards = [
    {
        title: 'تعداد کل درخواست ها',
        stats: '25,369',
        avatarIcon: 'tabler-cpu',
        avatarIconSize: 26,
        avatarColor: 'primary',
        avatarSize: 42,
        avatarSkin: 'light'
    },
    {
        title: 'روز گذشته',
        stats: '421',
        avatarIcon: 'tabler-server',
        avatarIconSize: 26,
        avatarColor: 'success',
        avatarSize: 42,
        avatarSkin: 'light'
    },
    {
        title: 'میزان کل استفاده',
        stats: '12,684.5 ساعت',
        avatarIcon: 'tabler-chart-pie-2',
        avatarIconSize: 26,
        avatarColor: 'error',
        avatarSize: 42,
        avatarSkin: 'light'
    },
    {
        title: 'روز گذشته',
        stats: '210.5 ساعت',
        avatarIcon: 'tabler-alert-octagon',
        avatarIconSize: 26,
        avatarColor: 'warning',
        avatarSize: 42,
        avatarSkin: 'light'
    }
]

function HorizontalCardOverview() {
    return (
        <Grid container spacing={6}>
            {cards.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <CardHorizontal {...item} />
                </Grid>
            ))}
        </Grid>
    )
}

export default HorizontalCardOverview