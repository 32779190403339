function secondsToHms(d) {
    d = Number(d);
    var hour = Math.floor(d / 3600);
    var minute = Math.floor(d % 3600 / 60);
    var second = Math.floor(d % 3600 % 60);

    return { hour, minute, second };
}

function displaySecond(d) {
    let { hour, minute, second } = secondsToHms(d);
    if (hour < 10) hour = "0" + hour
    if (minute < 10) minute = "0" + minute
    if (second < 10) second = "0" + second
    let display = `${hour}:${minute}:${second}`;
    return display
}

export default displaySecond;