import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'

function OurTeam() {
    return (
        <div className='mt-16 md:mt-28 flex flex-col px-4 text-center'>
            <div className="text-center mb-3 pb-1">
                <Chip label='تیم بزرگ ما' color='primary' variant='tonal' />
            </div>
            <h3 className="text-center mb-1">
                <span className="relative font-bold z-1 text-2xl">
                    پشتیبانی با
                    <img
                        src="/images/pages/landingpage/section-title-icon.png"
                        alt="laptop charging"
                        className="h-full w-[120%] top-[10px] left-[-12%] absolute object-contain bottom-0 z-1" />
                </span>
                <span className='font-normal text-2xl'> افراد واقعی</span>
            </h3>
            <Typography className='!text-textSecondary' variant='h6'>
                چه افرادی این سرویس عالی را پشتیبانی میکنند؟
            </Typography>
            <Grid container spacing={8}>                
                <Grid item xs={12} md={6} lg={3}>
                    <div className='relative h-[15rem]'>
                        <div className='bg-[#D9F8FC] rounded-tl-[5.625rem] rounded-tr-[1.25rem] w-full h-[9rem] absolute bottom-0'>

                        </div>
                        <img
                            src="/images/pages/landingpage/team-member-2.png"
                            className="absolute h-[12rem] bottom-0  m-auto left-0 right-0 z-0"
                            alt="human image" />
                    </div>
                    <div className="border-2 border-t-0 border-[#D9F8FC] text-center py-2">
                        <Typography variant='h5'>مهرداد قربانی</Typography>
                        <Typography className='!text-textSecondary' variant='h6'>مدیر پروژه</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className='relative h-[15rem]'>
                        <div className='bg-[#EAE8FD] rounded-tl-[5.625rem] rounded-tr-[1.25rem] w-full h-[9rem] absolute bottom-0'>

                        </div>
                        <img
                            src="/images/pages/landingpage/team-member-1.png"
                            className="absolute h-[12rem] bottom-0  m-auto left-0 right-0 z-0"
                            alt="human image" />
                    </div>
                    <div className="border-2 border-t-0 border-[#EAE8FD] text-center py-2">
                        <Typography variant='h5'>مجتبی رزمی</Typography>
                        <Typography className='!text-textSecondary' variant='h6'>برنامه نویس</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className='relative h-[15rem]'>
                        <div className='bg-[#FCE5E6] rounded-tl-[5.625rem] rounded-tr-[1.25rem] w-full h-[9rem] absolute bottom-0'>

                        </div>
                        <img
                            src="/images/pages/landingpage/team-member-3.png"
                            className="absolute h-[12rem] bottom-0  m-auto left-0 right-0 z-0"
                            alt="human image" />
                    </div>
                    <div className="border-2 border-t-0 border-[#FCE5E6] text-center py-2">
                        <Typography variant='h5'>شاهین فراهانی</Typography>
                        <Typography className='!text-textSecondary' variant='h6'>طراح</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className='relative h-[15rem]'>
                        <div className='bg-[#DFF7E9] rounded-tl-[5.625rem] rounded-tr-[1.25rem] w-full h-[9rem] absolute bottom-0'>

                        </div>
                        <img
                            src="/images/pages/landingpage/team-member-4.png"
                            className="absolute h-[12rem] bottom-0  m-auto left-0 right-0 z-0"
                            alt="human image" />
                    </div>
                    <div className="border-2 border-t-0 border-[#DFF7E9] text-center py-2">
                        <Typography variant='h5'>مارک زاکربرگ</Typography>
                        <Typography className='!text-textSecondary' variant='h6'>تدارکات</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default OurTeam