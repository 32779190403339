import React from 'react'

// MUI Imports
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// Component Imports
import DialogCloseButton from '../../../component/dialog/DialogCloseButton'

function DaramRoshKarMikonam({ open, setOpen }) {
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='sm'
            scroll='body'
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <DialogCloseButton onClick={() => setOpen(false)} disableRipple>
                <Icon icon='tabler-x' />
            </DialogCloseButton>
            <DialogTitle variant='h4' className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-10 sm:pli-16'>
                دارم روش کار میکنم
                <Typography component='span' className='flex flex-col items-center'>
                    یه برنامه نویس کارای اداری رو خوب انجام نمیده
                </Typography>
                <Typography component='span' className='flex flex-col items-center'>
                    یکم طول میکشه تا درگاه پرداخت بگیرم
                </Typography>
            </DialogTitle>
            <DialogContent className='pbs-0 sm:pli-16 sm:pbe-20'>
                <Typography component='span'>
                    اگر خواستین فعلا تلگرام پیام بدین 😎
                </Typography>
                <Divider className='!my-3' />
                <div className='flex justify-end'>
                    <Typography component='span' dir="ltr">
                        @Merdad86
                    </Typography>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DaramRoshKarMikonam