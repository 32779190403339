import React, { useEffect } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'

// Component Imports
import HorizontalCardOverview from './horizontal_card_overview/HorizontalCardOverview'
import BarchartTask from './barchart_task/BarchartTask'
import RadarchartTask from './radarchart_task/RadarchartTask'


Dashboard.path = "/dashboard"
function Dashboard() {

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <HorizontalCardOverview />
      </Grid>
      <Grid item xs={12} lg={8}>
        <BarchartTask />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <RadarchartTask />
      </Grid>
    </Grid>

  )
}

export default Dashboard