import React from 'react'
import { Routes, Route } from 'react-router-dom'
import AuthRoutes from '../../routes/auth/AuthRoutes'
import UserLayout from '../user/UserLayout'
import { useSettings } from '../../hooks/useSetting'


function AuthLayout() {
    const { user, settings } = useSettings();
    return (
        <>
            {!user.isLogin ? (
                <Routes>
                    <Route path='/*' element={<AuthRoutes />} />
                </Routes>
            ) : (
                <UserLayout />
            )}
            {settings.isLoading && (
                <div className='fixed inset-0 z-50 flex justify-center items-center bg-white/20 backdrop-blur-sm'>
                    <div className="rounded-md h-12 w-12 border-4 border-t-4 border-primary animate-spin"></div>
                </div>
            )}

        </>
    )
}

export default AuthLayout