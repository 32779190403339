import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import classnames from 'classnames'

// Component Import
import CustomAvatar from '../../../component/avatar/CustomAvatar'

function LandingContactUs({ mode }) {
    return (
        <div className={classnames(
            'gap-1 pt-24 pb-12 px-4',
            {
                'bg-[#F8F7FA]': mode == 'light',
                'bg-[#1e2130]': mode == 'dark',
            }
        )}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={9}>
                    <div className="text-center mb-3 pb-1">
                        <Chip label='تماس با ما' color='primary' variant='tonal' />
                        <h3 className="text-center my-2">
                            شما هنوز سوال دارید؟
                        </h3>
                        <div className='text-center'>
                            <Typography className='!text-textSecondary' variant='h6'>
                                اگر نمی توانید سؤالی را در سؤالات متداول ما پیدا کنید، همیشه می توانید با ما تماس بگیرید. ما به زودی به شما پاسخ خواهیم داد
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Grid container spacing={6} className='!mt-8'>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <div className='flex justify-center items-center flex-col gap-4 py-4'>
                                        <CustomAvatar variant='rounded' color='primary' skin='light' size={46}>
                                            <Icon icon='tabler-mail' fontSize={26} />
                                        </CustomAvatar>
                                        <div className='flex items-center flex-col gap-1'>
                                            <Typography variant='h5'>merdad.ghorbani86@gmail.com</Typography>
                                            <Typography>بهترین راه برای دریافت سریعتر پاسخ!</Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardContent>
                                    <div className='flex justify-center items-center flex-col gap-4 py-4'>
                                        <CustomAvatar variant='rounded' color='primary' skin='light' size={46}>
                                            <Icon icon='tabler-phone' fontSize={26} />
                                        </CustomAvatar>
                                        <div className='flex items-center flex-col gap-1'>
                                            <Typography dir="ltr" variant='h5'>+98 919 4168663</Typography>
                                            <Typography>کمک به شما مایه خرسندی ماست!</Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default LandingContactUs