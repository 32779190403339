import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Icon } from '@iconify/react/dist/iconify.js'

const items = [
    {
        icon: 'bi:laptop',
        rate: '7.1K +',
        first_desc: 'تیکت های انجام شده توسط',
        second_desc: 'پشتیبانی',
        iconColor: 'text-purple-400',
        borderColor: 'border-purple-400'
    },
    {
        icon: 'mage:user-circle-fill',
        rate: '7.1K +',
        first_desc: 'به انجمن خلاقیت ها',
        second_desc: 'بپیوندید',
        iconColor: 'text-green-400',
        borderColor: 'border-green-400'
    },
    {
        icon: 'ion:diamond',
        rate: '7.1K +',
        first_desc: 'دارای امتیاز بالای',
        second_desc: 'محصولات',
        iconColor: 'text-sky-400',
        borderColor: 'border-sky-400'
    },
    {
        icon: 'mdi:tick-decagram',
        rate: '7.1K +',
        first_desc: 'ضمانت برگشت',
        second_desc: 'پول شما',
        iconColor: 'text-orange-400',
        borderColor: 'border-orange-400'
    },
]

function LandingTopScore() {
    return (
        <div className='py-10 px-4'>
            <Grid container justifyContent="center" spacing={6}>
                {items.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} xl={3} key={index}>
                        <div className={`flex flex-col items-center gap-3 py-4 border-[1px] ${item.borderColor}`}>
                            <Icon icon={item.icon} fontSize={54} className={item.iconColor} />
                            <Typography dir="ltr" variant='h3'>{item.rate}</Typography>
                            <div className='text-center'>
                                <Typography>{item.first_desc}</Typography>
                                <Typography>{item.second_desc}</Typography>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default LandingTopScore