import React, { useEffect, useState } from 'react'

// MUI imports
import { styled } from '@mui/material/styles';
import List from "@mui/material/List";
import MenuItem from './menu_item/MenuItem';

const FireNav = styled(List)({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
        marginTop: 2
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 8,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});


function SidebarMenu({ isDrawerOpen }) {
    const [version, setVersion] = useState(0)

    useEffect(() => {
        if (!isDrawerOpen) {
            setVersion(version + 1)
        }
    }, [isDrawerOpen])

    return (
        <FireNav
            component="nav"
            aria-labelledby="nested-list-subheader">
            <MenuItem title="داشبورد" icon='tabler-smart-home' href='/dashboard' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="افزایش اعتبار" icon='f7:money-dollar-circle' href='/credit-plans' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="تبدیل در لحظه" icon='carbon:microphone-filled' href='/instant_conversion' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="گزارش ها" icon='ic:outline-task' href='/reports' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="پروفایل کاربری" icon='lucide:user-round' href='/profile' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="ربات تلگرام" icon='hugeicons:telegram' href='/telegram-bot' isDrawerOpen={isDrawerOpen} />
            <MenuItem title="API" icon='ic:sharp-api' href='/api' isDrawerOpen={isDrawerOpen} />
        </FireNav>
    );
}

export default SidebarMenu