import React, { useEffect } from 'react'

// Hooks import 
import useMediaQuery from '../../hooks/useMediaQuery'
import { useSettings } from '../../hooks/useSetting'
import useSWR from 'swr'

// Component Imports
import PermanentSidebarDrawer from './drawer/PermanentSidebarDrawer'
import SidebarDrawer from './drawer/SidebarDrawer'
import Navbar from './navbar/Navbar'
import UserRoutes from '../../routes/user/UserRoutes'

// Api Imports 
import { getUserReq, privateUrlEndpoint as cacheKey } from '../../api/privateApi'

function UserLayout() {

    // Hooks 
    const { updateSettings, updateUser } = useSettings();
    const isBreakPointReached = useMediaQuery('1200px');

    const {
        isLoading,
        error,
        data,
        mutate,
    } = useSWR(cacheKey, getUserReq, {
        onSuccess: data => {
            updateUser(data)
        },
        shouldRetryOnError: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    useEffect(() => {
        if (isBreakPointReached) {
            updateSettings({ isDrawerOpen: false, drawer: 'temp' })
        } else {
            updateSettings({ isDrawerOpen: true, drawer: 'permanent' })
        }
    }, [isBreakPointReached])

    return (
        <div className='flex'>
            {isBreakPointReached ? <SidebarDrawer /> : <PermanentSidebarDrawer />}
            <div className='pb-6 px-6 xl:px-28 w-full'>
                <Navbar />
                <div className='mt-12'>
                    <UserRoutes />
                </div>
            </div>
        </div>
    )
}

export default UserLayout