import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from '../../pages/dashboard/Dashboard'
import CreditPlans from '../../pages/credit_plans/CreditPlans'
import Profile from '../../pages/profile/Profile'
import Reports from '../../pages/reports/Reports'
import VerifyEmail from '../../pages/verify_email/VerifyEmail'
import API from '../../pages/api/API'
import TelegramBot from '../../pages/telegram_bot/TelegramBot'
import InstantConversion from '../../pages/instant_conversion/InstantConversion'

function UserRoutes() {
    return (
        <Routes>
            <Route path={Dashboard.path} element={<Dashboard />} />
            <Route path={InstantConversion.path} element={<InstantConversion />} />
            <Route path={Profile.path} element={<Profile />} />
            <Route path={CreditPlans.path} element={<CreditPlans />} />
            <Route path={Reports.path} element={<Reports />} />
            <Route path={API.path} element={<API />} />
            <Route path={TelegramBot.path} element={<TelegramBot />} />
            <Route path={VerifyEmail.path} element={<VerifyEmail />} />
            <Route path='*' element={<Navigate to={Dashboard.path} />} />
        </Routes>
    )
}

export default UserRoutes