import React, { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import Typography from '@mui/material/Typography'
import { useColorScheme, useTheme } from '@mui/material/styles'

// Third Party Imports
import classnames from 'classnames'
import { Icon } from '@iconify/react/dist/iconify.js'

// Components Imports
import CustomAvatar from '../../../component/avatar/CustomAvatar'
import OptionMenu from '../../../component/option_menu/OptionMenu'

// Util Imports
import { rgbaToHex } from '../../../utils/rgbaToHex'

// Styled Component Imports
import AppReactApexCharts from '../../../styles/chart/AppReactApexCharts'

// Vars
const tabData = [
    {
        type: 'تعداد',
        avatarIcon: 'tabler-chart-bar',
        series: [{ data: [35, 25, 15, 40, 42, 25, 48, 8, 30] }]
    },
    {
        type: 'زمان',
        avatarIcon: 'tabler-chart-pie-2',
        series: [{ data: [5, 9, 12, 18, 20, 25, 30, 36, 48] }]
    }
]

const renderTabs = value => {
    return tabData.map((item, index) => (
        <Tab
            key={index}
            value={item.type}
            className='!mie-4'
            label={
                <div
                    className={classnames(
                        'flex flex-col items-center justify-center gap-2 is-[110px] bs-[100px] border rounded-xl',
                        item.type === value ? 'border-solid border-[var(--mui-palette-primary-main)]' : 'border-dashed'
                    )}
                >
                    <CustomAvatar variant='rounded' skin='light' size={38} {...(item.type === value && { color: 'primary' })}>
                        <Icon icon={item.avatarIcon} className={classnames('text-[24px]', { 'text-textSecondary': item.type !== value })} />
                    </CustomAvatar>
                    <Typography className='!font-bold capitalize' color='text.primary'>
                        {item.type}
                    </Typography>
                </div>
            }
        />
    ))
}

const renderTabPanels = (value, theme, options, colors) => {
    return tabData.map((item, index) => {
        const max = Math.max(...item.series[0].data)
        const seriesIndex = item.series[0].data.indexOf(max)

        const finalColors = colors.map((color, i) =>
            seriesIndex === i ? rgbaToHex(`rgb(${theme.palette.primary.mainChannel} / 1)`) : color
        )

        return (
            <TabPanel key={index} value={item.type} className='!p-0'>
                <AppReactApexCharts
                    type='bar'
                    height={233}
                    options={{ ...options, colors: finalColors }}
                    series={item.series}
                />
            </TabPanel>
        )
    })
}

function BarchartTask({ serverMode }) {
    // States
    const [value, setValue] = useState('تعداد')

    // Hooks
    const theme = useTheme()
    const { mode } = useColorScheme()

    // Vars
    const _mode = (mode === 'system' ? serverMode : mode) || serverMode
    const disabledText = rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.4)`)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const colors = Array(9).fill(rgbaToHex(`rgb(${theme.palette.primary.mainChannel} / 0.16)`))

    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false }
        },
        plotOptions: {
            bar: {
                borderRadius: 6,
                distributed: true,
                columnWidth: '33%',
                borderRadiusApplication: 'end',
                dataLabels: { position: 'top' }
            }
        },
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: {
            offsetY: -11,
            formatter: val => `${val}k`,
            style: {
                fontWeight: 500,
                colors: [rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.9)`)],
                fontSize: theme.typography.body1.fontSize
            }
        },
        colors,
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        grid: {
            show: false,
            padding: {
                top: -19,
                left: -4,
                right: 0,
                bottom: -11
            }
        },
        xaxis: {
            axisTicks: { show: false },
            axisBorder: { color: rgbaToHex(`rgb(${theme.mainColorChannels[_mode]} / 0.12)`) },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            labels: {
                style: {
                    colors: disabledText,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.body2.fontSize
                }
            }
        },
        yaxis: {
            labels: {
                offsetX: -18,
                formatter: val => `$${val}k`,
                style: {
                    colors: disabledText,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.body2.fontSize
                }
            }
        },
        responsive: [
            {
                breakpoint: 1450,
                options: {
                    plotOptions: {
                        bar: { columnWidth: '45%' }
                    }
                }
            },
            {
                breakpoint: 600,
                options: {
                    dataLabels: {
                        style: {
                            fontSize: theme.typography.body2.fontSize
                        }
                    },
                    plotOptions: {
                        bar: { columnWidth: '58%' }
                    }
                }
            },
            {
                breakpoint: 500,
                options: {
                    plotOptions: {
                        bar: { columnWidth: '70%' }
                    }
                }
            }
        ]
    }

    return (
        <Card>
            <CardHeader
                title='نمودار تسک ها'
                subheader='بررسی اجمالی مصرف سالانه'
                action={<OptionMenu options={['هفته گذشته', 'ماه گذشته', 'سال گذشته']} />}
            />
            <CardContent>
                <TabContext value={value}>
                    <TabList
                        variant='scrollable'
                        scrollButtons='auto'
                        onChange={handleChange}
                        aria-label='earning report tabs'
                        className='!border-0 mbe-10'
                        sx={{
                            '& .MuiTabs-indicator': { display: 'none !important' },
                            '& .MuiTab-root': { padding: '0 !important', border: '0 !important' }
                        }}
                    >
                        {renderTabs(value)}
                    </TabList>
                    {renderTabPanels(value, theme, options, colors)}
                </TabContext>
            </CardContent>
        </Card>
    )
}

export default BarchartTask