import React, { useEffect, useState } from 'react'

// Mui Imports
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Microphone({ updateTranscript, updateIntermTranscript, language }) {

    const {
        transcript,
        interimTranscript,
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();


    useEffect(() => {
        updateIntermTranscript(interimTranscript)
    }, [interimTranscript])

    useEffect(() => {
        updateTranscript(finalTranscript)
        resetTranscript()
    }, [finalTranscript])

    const handleChangeListening = () => {
        if (listening) {
            SpeechRecognition.stopListening()
        } else {
            SpeechRecognition.startListening({ language: language, continuous: true })
        }
    }

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div className='text-center flex flex-col items-center gap-3'>
            <div
                className='flex items-center justify-center bg-primary w-20 h-20 rounded-full shadow-2xl hover:scale-110 transition-all duration-200 cursor-pointer'
                onClick={handleChangeListening}>
                <Icon
                    className='text-white'
                    icon={!listening ? 'mdi:microphone-off' : 'mdi:microphone'}
                    fontSize={48} />
            </div>
            <Typography className='!font-bold'>
                {
                    listening ? 'صحبت کنید...' : 'دکمه ضبط صدا را لمس کرده و صحبت کنید'
                }
            </Typography>
        </div>
    )
}

export default Microphone