import { useState } from 'react';

// MUI Imports
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';

// Hooks import 
import { useSettings } from '../../../hooks/useSetting';

// Component imports
import Logo from '../../../component/shared/logo/Logo';


// Third-party imports
import { Icon } from '@iconify/react';
import SidebarMenu from '../sidebar_menu/SidebarMenu';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

function SidebarDrawer() {
    const { settings, updateSettings } = useSettings();

    const handleCloseDrawer = () => {
        updateSettings({ isDrawerOpen: false })
    }

    return (
        <Drawer
            dir='rtl'
            anchor='left'
            PaperProps={{
                sx: { width: "240px" },
            }}
            variant="temporary" open={settings.isDrawerOpen}>
            <DrawerHeader>
                <div className='flex justify-between items-center w-full px-4'>
                    <Logo hiddenText={!settings.isDrawerOpen} />
                    <Icon className='cursor-pointer' icon='mi:close' style={{ fontSize: '24px' }} onClick={handleCloseDrawer} />
                </div>
            </DrawerHeader>
            <Divider />
            <SidebarMenu isDrawerOpen={settings.isDrawerOpen} />
        </Drawer>
    )
}

export default SidebarDrawer