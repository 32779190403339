import React, { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// Component Imports
import Logo from '../../component/shared/logo/Logo'

// Styled Component Imports
import AuthIllustrationWrapper from '../../component/shared/AuthIllustrationWrapper/AuthIllustrationWrapper'

// Third-party Imports
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify'

// Hook Imports
import { useSettings } from '../../hooks/useSetting'
import { useNavigate } from 'react-router-dom'

// Api Imports
import { verifyEmailReq } from '../../api/privateApi'

VerifyEmail.path = "/verify-email"
function VerifyEmail() {

    const [otp, setOtp] = useState('')

    const { updateUser, updateSettings } = useSettings();
    const navigate = useNavigate();

    const handleValidateEmail = async () => {
        try {
            if (otp.length == 6) {
                updateSettings({ isLoading: true })
                let newUser = await verifyEmailReq({ otp_code: otp })
                updateSettings({ isLoading: false })
                updateUser(newUser)
                navigate('/profile')
            } else {
                toast.error('کد تایید را کامل وارد کنید')
            }
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    return (
        <div className='flex justify-center mt-44'>
            <AuthIllustrationWrapper>
                <Card className='flex flex-col sm:is-[450px]'>
                    <CardContent className='sm:!p-12'>
                        <div className='flex justify-center mbe-6'>
                            <Logo />
                        </div>
                        <div className='flex flex-col gap-1 mbe-6'>
                            <Typography variant='h4'>احراز هویت ایمیل 💬</Typography>
                            <Typography>
                                ما یک کد تأیید به ایمیل شما ارسال کردیم. کد ارسال شده را در فیلد زیر وارد کنید.
                            </Typography>
                            <Typography className='font-medium' color='text.primary'>
                                ******1234
                            </Typography>
                        </div>
                        <form noValidate autoComplete='off' onSubmit={e => e.preventDefault()} className='flex flex-col gap-6'>
                            <div className='mt-8 flex justify-center' dir='ltr'>
                                <OtpInput
                                    dir="ltr"
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    // renderSeparator={<span></span>}
                                    inputType='number'
                                    isInpuutNum={true}
                                    renderInput={(props) => <input dir='ltr' {...props} className='!w-12 h-12 mx-1 border rounded-md border-gray-400 text-center text-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' />}
                                />
                            </div>
                            <Button fullWidth variant='contained' type='submit' onClick={handleValidateEmail}>
                                تایید
                            </Button>
                            <div className='flex justify-center items-center flex-wrap gap-2'>
                                <Typography>کد را دریافت نکردید؟</Typography>
                                <Typography color='primary' className='underline cursor-pointer'>
                                    ارسال مجدد
                                </Typography>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </AuthIllustrationWrapper>
        </div>
    )
}

export default VerifyEmail