import React from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

const headerTxt = `{
    'Authorization': 'token yourApiKey'
}`

const trueResponse = `{
    "setting": {
        "success": 1,
        "message": "عملیات با موفقیت به پایان رسید"
    },
    "data": "این درخواست برای تست به صورت فایل میباشد "
}`

const wrongReponse = `{
    "setting": {
        "success": 0,
        "message": "فایل موجود نیست."
    },
    "data": []
}`

function ApiDocs() {
    return (
        <div className='mt-12'>
            <Card>
                <CardContent>
                    <Typography variant='h4'>Authorization</Typography>
                    <div className='flex flex-col gap-2 mt-6'>
                        <Typography variant='h5'>ورود و استفاده از توکن</Typography>
                        <Typography>
                            استفاده از apiهای غیر عمومی بگوبگم نیاز به ارسال api-key به عنوان HTTP Header دارید. برای این کار، پس از ثبت‌نام و ورود در وبسایت به صفحه‌ی API در پنل کاربری خود رفته و Api-Key خود را بردارید و به صورت زیر در Header ریکوئست‌های خود قرار دهید:
                        </Typography>
                        <Typography>
                            Authorization: Token yourApiKey
                        </Typography>
                    </div>
                    <div className='flex flex-col gap-2 mt-6'>
                        <Typography variant='h5'>خروج و سوزاندن توکن</Typography>
                        <Typography>
                            دقت کنید که api-keyهای تولید شده منقضی نمیشوند و در صورت تمایل به خروج و غیرفعال کردن آن‌ها به صفحه‌ی امنیت در پنل کاربری خود بروید.
                        </Typography>
                        <Typography>
                            توجه فرمایید که api-key شما همانند نام کاربری و رمز عبور شما می‌تواند برای ورود به حساب کاربریتان استفاده شود و به هیچ وجه آن‌ را در اختیار افراد دیگر قرار ندهید. در صورتیکه افراد دیگر تصادفا به Token شما دسترسی پیدا کردند سریعا آن را از پنل کاربری خود غیرفعال کنید.
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            <Card className='mt-8 mb-28'>
                <CardContent>
                    <div className='flex flex-col gap-2'>
                        <Typography variant='h5'>تبدیل صدا به متن فارسی</Typography>
                        <Typography>
                            در این API توسعه دهنده فایل خود را به آدرس API ارسال کرده و متن را دریافت می کند.
                        </Typography>
                        <div className='bg-cyan-900 mt-2 rounded-md px-4 py-3'>
                            <Typography dir="ltr" className='!text-teal-400'>
                                POST : https://api.begobegam.com/api/speech/speech_to_text
                            </Typography>
                        </div>
                        <Typography variant='h5' className='!mt-4'>Header درخواست</Typography>
                        <div className='bg-cyan-900 mt-2 rounded-md px-4 py-3'>
                            <Typography dir="ltr" className='!text-teal-400 whitespace-pre-wrap'>
                                {headerTxt}
                            </Typography>
                        </div>
                        <Typography variant='h5' className='!mt-4'>Body درخواست</Typography>
                        <Typography>
                            body باید به صورت form-data باشد و همچنین پارامتر file باید حاوی فایل مورد نظرتان باشد.
                        </Typography>
                        <Grid container spacing={8} className='!mt-6'>
                            <Grid item sm={12} md={6}>
                                <Typography className='!font-bold'>
                                    در صورت خطا، خروجی به صورت زیر خواهد بود:
                                </Typography>
                                <div className='bg-cyan-900 mt-2 rounded-md px-4 py-3'>
                                    <Typography dir="ltr" className='!text-teal-400 whitespace-pre-wrap'>{wrongReponse}</Typography>
                                </div>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography className='!font-bold'>
                                    در صورت موفقیت آمیز بودن، خروجی به صورت زیر خواهد بود:
                                </Typography>
                                <div className='bg-cyan-900 mt-2 rounded-md px-4 py-3'>
                                    <Typography dir="ltr" className='!text-teal-400 whitespace-pre-wrap'>{trueResponse}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default ApiDocs