import React from 'react'

// MUI imports 
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Icon } from '@iconify/react/dist/iconify.js';

import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useSettings } from '../../../../hooks/useSetting';



function MenuItem({ title, icon, href, child, isDrawerOpen, isTemp }) {

    let location = useLocation();
    let fontSize = '24px'
    if (child) fontSize = '18px'
    const { settings, updateSettings } = useSettings();

    const handleClickItem = () => {
        if (settings?.drawer != 'permanent') {
            updateSettings({ isDrawerOpen: false })
        }
    }

    return (
        <div className={classnames(
            'rounded-sm mx-3 mt-[6px]',
            {
                'pl-3': child,
                'bg-primary': location.pathname == href
            }
        )}>
            <ListItemButton className='!pr-3' href={href} LinkComponent={Link} to={href} onClick={handleClickItem}>
                <ListItemIcon>
                    <Icon
                        className={classnames(
                            'text-textPrimary',
                            {
                                'text-textSecondary': child,
                                'text-white': location.pathname === href
                            }
                        )}
                        icon={icon}
                        style={{ fontSize: fontSize }} />
                </ListItemIcon>
                {!!isDrawerOpen && <span className={classnames(
                    'text-textPrimary text-[15px]',
                    {
                        'text-textSecondary': child,
                        'text-[15px]': child,
                        'text-white': location.pathname == href
                    }
                )}>
                    {title}
                </span>}
            </ListItemButton>
        </div>
    )
}

export default MenuItem