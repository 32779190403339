import React from "react";
import { SettingsProvider } from './settting/SettingsContext'
import ThemProvider from './theme/ThemProvider'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Providers({ children }) {
    return (
        <SettingsProvider mode="system">
            <ThemProvider>
                {children}
                <ToastContainer rtl hideProgressBar/>
            </ThemProvider>
        </SettingsProvider>
    )
}

export default Providers