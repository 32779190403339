import React from 'react'

// MUI Imports
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { Button, Divider } from '@mui/material'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import { Link } from 'react-router-dom'

// Component Imports
import DialogCloseButton from '../../../../component/dialog/DialogCloseButton'


function EmailSentDialog({ open, setOpen, email }) {
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='xs'
            scroll='body'
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <DialogCloseButton onClick={() => setOpen(false)} disableRipple>
                <Icon icon='tabler-x' />
            </DialogCloseButton>
            <DialogTitle variant='h4' className='sm:pbs-16 sm:pbe-10 sm:pli-16'>
                <div className='flex items-center gap-3'>
                    <Typography variant='h4'>ایمیل ارسال شد</Typography>
                    <Icon icon="clarity:email-line" fontSize={28} className='text-sky-400' />
                </div>

            </DialogTitle>
            <DialogContent className='pbs-0 sm:pli-16 sm:pbe-20'>
                <Typography>
                    لینک بازنشانی رمز عبور برای آدرس ایمیل شما ارسال شد
                    <br />
                    <br />
                    لطفاً برای ادامه ایمیل خود را چک کنید.
                </Typography>
                <Button
                    fullWidth
                    variant='contained'
                    className='!mt-8'
                    LinkComponent={Link}
                    to="/login">بازگشت به صفحه ورود</Button>
            </DialogContent>
        </Dialog>
    )
}

export default EmailSentDialog