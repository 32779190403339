import React from 'react'

// MUI Imports
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// Component Imports
import DialogCloseButton from '../../../component/dialog/DialogCloseButton'
import formatBytes from '../../../utils/format_byte'

function TaskDetailsDialog({ open, setOpen, task }) {
    if (!task) {
        return
    }

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={() => setOpen(false)}
            maxWidth='sm'
            scroll='body'
            sx={{ '& .MuiDialog-paper': { overflow: 'visible' } }}
        >
            <DialogCloseButton onClick={() => setOpen(false)} disableRipple>
                <Icon icon='tabler-x' />
            </DialogCloseButton>
            <DialogTitle variant='h4' className='flex gap-2 flex-col text-center sm:pbs-16 sm:pbe-10 sm:pli-16'>
                نمایش جزئیات تسک
                <Typography component='span' className='flex flex-col items-center'>
                    {new Date(task.create_at).toLocaleString("fa-IR")}
                </Typography>
            </DialogTitle>
            <DialogContent className='pbs-0 sm:pli-16 sm:pbe-20'>
                <Typography component='span'>
                    {task.transcribe}
                </Typography>
                <Divider className='!my-3' />
                <div className='flex gap-1 items-center'>
                    <Typography>مدت زمان:</Typography>
                    <Typography variant='subtitle1' className='!pt-1'>{task.duration}</Typography>
                </div>
                <div className='flex gap-1 items-center'>
                    <Typography>حجم فایل:</Typography>
                    <Typography dir="ltr" variant='subtitle1' className='!pt-1'>{formatBytes(task.file_size)}</Typography>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TaskDetailsDialog