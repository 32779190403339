import React from 'react'

// MUI Imports
import IconButton from '@mui/material/IconButton'

// Third-party imports
import { Icon } from '@iconify/react/dist/iconify.js'

function EditorToolbar({ editor }) {

    if (!editor) {
        return null
    }

    return (
        <div className='flex flex-wrap gap-x-3 gap-y-1 p-2'>
            <IconButton
                {...(editor.isActive('bold') && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().toggleBold().run()}
            >
                <Icon icon='tabler:bold' />
            </IconButton>
            <IconButton
                {...(editor.isActive('underline') && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().toggleUnderline().run()}
            >
                <Icon icon='tabler:underline' />
            </IconButton>
            <IconButton
                {...(editor.isActive('italic') && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().toggleItalic().run()}
            >
                <Icon icon='tabler:italic' />
            </IconButton>
            <IconButton
                {...(editor.isActive('strike') && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().toggleStrike().run()}
            >
                <Icon icon='tabler:strikethrough' />
            </IconButton>
            <IconButton
                {...(editor.isActive({ textAlign: 'right' }) && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
            >
                <Icon icon='tabler:align-right' />
            </IconButton>
            <IconButton
                {...(editor.isActive({ textAlign: 'center' }) && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
            >
                <Icon icon='tabler:align-center' />
            </IconButton>
            <IconButton
                {...(editor.isActive({ textAlign: 'left' }) && { color: 'primary' })}
                variant='outlined'
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
            >
                <Icon icon='tabler:align-left' />
            </IconButton>
            <IconButton
                {...(editor.isActive({ textAlign: 'justify' }) && { color: 'primary' })}
                variant='outlined'
                size='small'
                onClick={() => editor.chain().focus().setTextAlign('justify').run()}
            >
                <Icon icon='tabler:align-justified' />
            </IconButton>
        </div>
    )
}

export default EditorToolbar