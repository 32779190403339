import React, { useMemo, useState, useEffect } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

// Hook Imports
import { useSettings } from '../../hooks/useSetting'

// Third party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import classnames from 'classnames'
import {
    useReactTable,
    flexRender,
    createColumnHelper,
    getCoreRowModel
} from '@tanstack/react-table'

// Component Imports
import FileUploader from '../../component/shared/file_uploader/FileUploader';
import TablePaginationComponent from '../../component/pagination/TablePaginationComponent'
import TaskDetailsDialog from './task_details_dialog/TaskDetailsDialog'

// Util Imports
import displaySecond from '../../utils/second_to_hms';
import formatBytes from '../../utils/format_byte';

// Api imports 
import { getTaskListReq, newTaskReq, getUserReq } from '../../api/privateApi'

// Style Imports 
import tableStyles from '../../styles/table.module.css'
import { Button } from '@mui/material'

// Column Definitions
const columnHelper = createColumnHelper()


Reports.path = "/reports"
function Reports() {

    // State
    const [data, setData] = useState([])
    const [taskCount, setTaskCount] = useState(0)
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)

    // Hooks 
    const { updateSettings, updateUser } = useSettings()

    useEffect(() => {
        getTaskList()
    }, [page])


    const columns = useMemo(() => [
        columnHelper.accessor('transcribe', {
            header: 'رونوشت',
            cell: ({ row }) => {
                let text = row.original.transcribe
                if (row.original.transcribe.length > 20) text = row.original.transcribe.substring(0, 20) + '...'
                return (
                    <div>
                        <Typography>
                            {text}
                        </Typography>
                    </div>
                )
            }
        }),
        columnHelper.accessor('create_at', {
            header: 'تاریخ ثبت',
            cell: ({ row }) => (
                <div>
                    <Typography>
                        <span dir="ltr">
                            {new Date(row.original.create_at).toLocaleString("fa-IR")}
                        </span>
                    </Typography>
                </div>
            )
        }),
        columnHelper.accessor('from', {
            header: 'از طریق',
            cell: ({ row }) => (
                <div>
                    <Chip label={row.original.from} color='primary' variant='tonal' size='small' className='w-24' />
                </div>
            )
        }),
        columnHelper.accessor('duration', {
            header: 'مدت زمان',
            cell: ({ row }) => (
                <div>
                    <Typography>
                        {displaySecond(row.original.duration)}
                    </Typography>
                </div>
            )
        }),
        columnHelper.accessor('file_size', {
            header: 'حجم فایل',
            cell: ({ row }) => (
                <div>
                    <Typography>
                        <span dir="ltr">
                            {formatBytes(row.original.file_size)}
                        </span>
                    </Typography>
                </div>
            )
        }),
        columnHelper.accessor('action', {
            header: 'عملیات',
            cell: ({ row }) => (
                <div className='flex items-center cursor-pointer mr-2' onClick={() => handleOpenDialog(row.original)}>
                    <Icon className='hover:text-primary' icon='tabler-eye' fontSize={22} />
                </div>
            )
        }),
    ], [])

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    const getTaskList = async () => {
        try {
            updateSettings({ isLoading: true })
            let reqBody = { page: page - 1 }
            let tasksRes = await getTaskListReq(reqBody)
            setData(tasksRes.tasks)
            setTaskCount(tasksRes.count)
            updateSettings({ isLoading: false })
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    const getUser = async () => {
        try {
            updateSettings({ isLoading: true })
            let user = await getUserReq()
            updateUser(user)
            updateSettings({ isLoading: false })
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    const onFileSelected = async (file) => {
        try {
            updateSettings({ isLoading: true })
            let formData = new FormData();
            formData.append("file", file, file.name);
            await newTaskReq(formData)
            await getUser()
            if (page !== 1) {
                setPage(1)
            } else {
                getTaskList();
            }

        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }

    const handleOpenDialog = (row) => {
        setSelectedTask(row)
        setOpen(true)
    }

    return (
        <Card>
            <CardContent className='gap-3'>
                <div className='flex justify-between'>
                    <Button variant='outlined' onClick={getTaskList}>
                        <Icon
                            className='ml-2'
                            icon='ic:round-refresh'
                            fontSize={20}
                        />
                        بروزرسانی
                    </Button>
                    <FileUploader onFileSelected={onFileSelected} />
                </div>
            </CardContent>
            <div className='overflow-x-auto'>
                <table className={tableStyles.table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    className={classnames({
                                                        'flex items-center': header.column.getIsSorted(),
                                                        'cursor-pointer select-none': header.column.getCanSort()
                                                    })}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{
                                                        asc: <i className='tabler-chevron-up text-xl' />,
                                                        desc: <i className='tabler-chevron-down text-xl' />
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                            </>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {table.getFilteredRowModel().rows.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan={table.getVisibleFlatColumns().length} className='text-center'>
                                    No data available
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {table
                                .getRowModel()
                                .rows.slice(0, table.getState().pagination.pageSize)
                                .map(row => {
                                    return (
                                        <tr key={row.id} className={classnames({ selected: row.getIsSelected() })}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                            ))}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    )}
                </table>
                <div >
                    <TablePaginationComponent table={table} page={page} setPage={setPage} count={taskCount} />
                </div>
                <TaskDetailsDialog open={open} setOpen={setOpen} task={selectedTask} />
            </div>
        </Card>
    )
}

export default Reports