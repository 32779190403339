import React, { useState } from 'react'

// MUI Imports
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

// Third-party Imports
import { Icon } from '@iconify/react/dist/iconify.js'
import { Controller, useForm } from 'react-hook-form'
import { object, minLength, string } from 'valibot'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { toast } from 'react-toastify'

// Hook Imports
import { useSettings } from '../../../hooks/useSetting'

// Component Imports
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

// Api Imports
import { updatePasswordReq } from '../../../api/privateApi'

const schema = object({
    password: string([
        minLength(1, 'لطفا رمز عبور را وارد کنید.'),
        minLength(5, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
    ]),
    newPassword: string([
        minLength(1, 'لطفا رمز عبور را وارد کنید.'),
        minLength(5, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
    ]),
    retypeNewPassword: string([
        minLength(1, 'لطفا رمز عبور را وارد کنید.'),
        minLength(5, 'رمز عبور حداقل باید ۶ کاراکتر باشد.')
    ]),
})


function ChangePassword() {

    // States
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [errorState, setErrorState] = useState(null)

    // Hooks 
    const { updateSettings } = useSettings()

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: valibotResolver(schema),
        defaultValues: {
            password: '',
            newPassword: '',
            retypeNewPassword: '',
        }
    })

    const onSubmit = async (data) => {
        if (data.newPassword !== data.retypeNewPassword) {
            toast.error('رمز عبور و تکرار آن باید مقدار یکسان داشته باشند.')
        }
        try {
            updateSettings({ isLoading: true })
            let changePassRes = await updatePasswordReq({ password: data.password, newPassword: data.newPassword })
            reset()
            toast.success('رمز عبور با موفقیت تغییر کرد.')
            updateSettings({ isLoading: false })
        } catch (err) {
            updateSettings({ isLoading: false })
        }
    }
    return (
        <Card>
            <CardContent>
                <Typography variant='h5'>تغییر رمز عبور</Typography>
                <form autoComplete='off' className='flex flex-col gap-6 mt-6' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name='password'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomTextField
                                {...field}
                                fullWidth
                                label='رمز عبور قبلی'
                                placeholder='············'
                                id='login-password'
                                type={isPasswordShown ? 'text' : 'password'}
                                onChange={e => {
                                    field.onChange(e.target.value)
                                    errorState !== null && setErrorState(null)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                                                <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...(errors.password && { error: true, helperText: errors.password.message })}
                            />
                        )}
                    />
                    <Controller
                        name='newPassword'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomTextField
                                {...field}
                                fullWidth
                                label='رمز عبور جدید'
                                placeholder='············'
                                id='new-password'
                                type={isPasswordShown ? 'text' : 'password'}
                                onChange={e => {
                                    field.onChange(e.target.value)
                                    errorState !== null && setErrorState(null)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                                                <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...(errors.newPassword && { error: true, helperText: errors.newPassword.message })}
                            />
                        )}
                    />
                    <Controller
                        name='retypeNewPassword'
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomTextField
                                {...field}
                                fullWidth
                                label='تکرار رمز عبور جدید'
                                placeholder='············'
                                id='retype-new-password'
                                type={isPasswordShown ? 'text' : 'password'}
                                onChange={e => {
                                    field.onChange(e.target.value)
                                    errorState !== null && setErrorState(null)
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton edge='end' onClick={() => setIsPasswordShown(show => !show)} onMouseDown={e => e.preventDefault()}>
                                                <Icon icon={isPasswordShown ? 'mdi:eye' : 'mdi:eye-off'} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...(errors.retypeNewPassword && { error: true, helperText: errors.retypeNewPassword.message })}
                            />
                        )}
                    />
                    <div className='flex justify-end'>
                        <Button type='submit' variant='contained'>ثبت</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default ChangePassword